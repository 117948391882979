import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import moment from "moment";

import { buscaAceitesFeitos } from "./aceites.helpers";

function Contador({
  match: {
    params: { evento: eventoPath },
  },
}) {
  const [aceitesFeitos, setAceitesFeitos] = useState([]);
  const [estaCarregando, setEstaCarregando] = useState(true);
  const [temErroCarregamento, setTemErroCarregamento] = useState(false);
  const [ultimoCarregamento, setUltimoCarregamento] = useState(null);

  const atualizarAceitesFeitos = () => {
    //setEstaCarregando(true);
    setTemErroCarregamento(false);

    buscaAceitesFeitos(eventoPath)
      .then((aceitesFeitosBuscados) => {
        setAceitesFeitos(aceitesFeitosBuscados);
        setUltimoCarregamento(moment());
      })
      .catch((err) => {
        console.log(err);
        setTemErroCarregamento(true);
      })
      .finally(() => {
        if (estaCarregando) {
          setEstaCarregando(false);
        }
      });
  };

  useEffect(() => {
    atualizarAceitesFeitos();

    window.perguntasInterval = setInterval(atualizarAceitesFeitos, 60000);

    return () => clearInterval(window.perguntasInterval);
  }, [eventoPath]);

  console.log(aceitesFeitos);

  const quantidadePdvs = aceitesFeitos.length
    ? aceitesFeitos.reduce((total, { quantidade_pdvs: qtdPdvs }) => total + qtdPdvs, 0)
    : 0;
  console.log(quantidadePdvs);

  return (
    <div className="contador-pagina">
      {estaCarregando && (
        <div className="contador-pagina__spinner-wrapper">
          <div className="spinner">
            <div className="rect1" />
            <div className="rect2" />
            <div className="rect3" />
            <div className="rect4" />
            <div className="rect5" />
          </div>
        </div>
      )}
      {!temErroCarregamento && (
        <div className="contador">
          <h1 className="contador__titulo">Campanha Milk Shake Amigos do Bem</h1>
          <img
            src="https://eventosapi.p9.digital/storage/eventos/bobs/img/logo-aceite-amigos.png"
            alt="Amigos do Bem"
            className="contador__logo"
          />
          <h2 className="contador__texto">PDV's Participantes</h2>
          <span className="contador__numero-pdvs">{quantidadePdvs}</span>
        </div>
      )}
      {temErroCarregamento && <p>Ops! Algo deu errado. Por favor, recarregue a página e tente novamente.</p>}
      <div className="contador-pagina__footer">
        <div className="contador-pagina__carregando">
          {ultimoCarregamento && ultimoCarregamento.isValid() && (
            <div className="contador-pagina__carregando-info">
              <span>Atualização automática. Última: {ultimoCarregamento.fromNow()}</span>
              <span onClick={atualizarAceitesFeitos} role="button" tabIndex={0} className="efeito-simples">
                Clique para atualizar agora
              </span>
            </div>
          )}
        </div>
        <div className="contador-pagina__creditos">
          <a
            className="contador-pagina__link"
            href="https://p9.digital"
            title="Plataforma de gestão de eventos e venda de inscrições online"
          >
            #PartiuEvento
          </a>
        </div>
      </div>
    </div>
  );
}

Contador.propTypes = {
  match: PropTypes.object.isRequired,
};

export default Contador;
