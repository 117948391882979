import React from "react";
import PropTypes from "prop-types";

class DadosEvento extends React.Component {
  state = {
    evento: "",
  }

  handleInput = ({ currentTarget: { value } }) => {
    const { atualizaEvento } = this.props;

    this.setState({
      evento: value,
    });

    atualizaEvento(value);
  };

  render() {
    const opcoes = [
      "Dia 01 Fev: Noite de Gala",
      "Dia 02 Fev: Convenção/Plenária",
      "Dias 01 e 02 Fev: Evento Completo (Noite de Gala + Convenção/Plenária)",
    ];

    const { evento } = this.state;

    return (
      <div className="dados-pessoais">
        <span className="dados-pessoais__titulo titulo-4">Selecione quais os Eventos</span>
        <div className="dados-pessoais__inputs">
          <div className="dados-pessoas__input-row">
            <div className="dados-pessoais__input-wrapper">
              <select
                name="evento"
                value={evento}
                onChange={this.handleInput}
                className="dados-pagamento__input dados-pagamento__input--select"
              >
                <option value="" disabled>Selecione o(s) Dia(s) que deseja participar</option>
                {opcoes.map(opcao => (
                  <option value={opcao} key={opcao}>{opcao}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DadosEvento.propTypes = {
  atualizaEvento: PropTypes.func.isRequired,
};

export default DadosEvento;
