import { applyMiddleware, createStore, combineReducers } from "redux";
//import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import { composeWithDevTools } from "redux-devtools-extension";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import { load, save } from "redux-localstorage-simple";

import evento from "./components/evento/reducer";
import comprar from "./components/comprar/reducer";

const rootReducer = combineReducers({
  evento,
  comprar,
});

const logger = createLogger({
  collapsed: (getState, action, logEntry) => !logEntry.error,
});

//criando a store
const middleware = [thunk];

//adiciona o logger somente em desenvolvimento
if (process.env.REACT_APP_LOGGER_ENABLED && process.env.REACT_APP_LOGGER_ENABLED === "true") {
  middleware.push(logger);
}

// const store = createStore(
//   rootReducer,
//   composeWithDevTools(
//     applyMiddleware(...middleware),
//   ),
// );

const store = createStore(rootReducer, load(), composeWithDevTools(applyMiddleware(...middleware, save())));

export default store;
