import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";

import { buscaEventoAction } from "../evento/actions";
import CriarPergunta from "./CriarPergunta";

const Pergunte = ({
  buscaEvento,
  eventoCarregado,
  evento,
  eventoCarregadoEm,
  match: {
    params: { evento: eventoPath },
  },
}) => {
  //se o evento ja estiver em cache, vamos verificar

  useEffect(() => {
    //const validade = 5 * 60 * 1000; //5 minutos
    const validade = 0;
    if (!eventoCarregado || evento.path !== eventoPath) {
      console.log("Nada em storage ou path de evento diferente, vamos buscar! 🔎");
      buscaEvento(eventoPath);
    }

    if (new Date() - new Date(eventoCarregadoEm) > validade) {
      console.log("Pergunte armazenado em storage já expirou, buscando novo ⏰");
      buscaEvento(eventoPath);
    }
  }, [eventoPath]);

  if (eventoPath !== "odonto-2020") {
    return <Redirect to="/odonto-2020" />;
  }

  console.log(evento);

  return (
    <div className="pergunte">
      <div className="pergunte-wrapper">
        <div className="pergunte-wrapper__topo">
          {!!evento && !!evento.logo && <img src={evento.logo} alt={evento.titulo} className="pergunte-logo" />}
          <h1 className="titulo-2">Pergunte ao Bob's</h1>
          <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iure, ratione.</p>
        </div>
        {!!eventoPath && <CriarPergunta evento={evento} eventoPath={eventoPath} />}
      </div>
      <div className="footer__creditos">
        <strong>#PartiuEvento</strong> - Plataforma de gestão de eventos e venda de inscrições online
      </div>
    </div>
  );
};

Pergunte.propTypes = {
  match: PropTypes.object.isRequired,
  buscaEvento: PropTypes.func.isRequired,
  evento: PropTypes.object.isRequired,
  eventoCarregado: PropTypes.bool,
  eventoCarregadoEm: PropTypes.string,
};

Pergunte.defaultProps = {
  eventoCarregado: false,
  eventoCarregadoEm: new Date().toString(),
};

const mapStateToProps = state => ({
  evento: state.evento.evento,
  eventoCarregado: state.evento.eventoCarregado,
  eventoCarregadoEm: state.evento.eventoCarregadoEm,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    buscaEvento: buscaEventoAction,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Pergunte);
