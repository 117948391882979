import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import client from "../../client";

import {
  atualizaCarrinhoAction,
  removeCarrinhoAction,
  ingressosEscolhidosAction,
  atualizaHospedagemNoCarrinhoAction,
  atualizaLeadAction,
  atualizaEventoAction,
  removeHospedagemNoCarrinhoAction,
  atualizaMeioPagamentoAction,
  limpaComprarAction,
  limpaCarrinhoAction,
} from "./actions";

import Ingressos from "./Ingressos";
import Hospedagem from "./Hospedagem";
import DadosEvento from "./DadosEvento";
import DadosPessoais from "./DadosPessoais";
import DadosPagamento from "./DadosPagamento";
import Progresso from "./Progresso";
import ComprarHeader from "./ComprarHeader";

class Comprar extends Component {
  state = {
    irEventoParaCheckout: false,
    pedidoFinalizado: false,
    codigo: "",
    tentandoPagamento: false,
    erroPagamento: false,
    ingressosNoCarrinho: 0,
    naoTemHospedagem: true,
  };

  precisaDeHotel = () => {
    this.setState({
      naoTemHospedagem: !this.state.naoTemHospedagem,
    });
  }

  pagamentoDemorouMuito = () => {
    clearTimeout(this.timeoutPagamento);

    this.setState({
      erroPagamento: true,
      tentandoPagamento: false,
    });
  };

  pedirBoleto = (boleto) => {
    clearTimeout(this.timeoutPagamento);

    this.timeoutPagamento = setTimeout(this.timeoutPagamento, 15000);

    const { tentandoPagamento } = this.state;
    if (tentandoPagamento) return false;

    this.setState({
      tentandoPagamento: true,
      erroPagamento: false,
    });

    console.log(boleto);

    this.submitCheckoutBoleto(boleto)
      .then(({ data }) => {
        clearTimeout(this.timeoutPagamento);

        console.log("data", data);
        if (data.success) {
          this.setState({
            tentandoPagamento: false,
            pedidoFinalizado: true,
            codigo: data.data,
          });

          const { limpaComprar } = this.props;
          limpaComprar();
        } else {
          clearTimeout(this.timeoutPagamento);

          if (data.data === "expirado") {
            console.log("possui produtos expirados, limpando carrinho");
            const { limpaComprar } = this.props;
            limpaComprar();
          }

          this.setState({
            erroPagamento: true,
            tentandoPagamento: false,
          });
        }
      })
      .catch((err) => {
        clearTimeout(this.timeoutPagamento);

        this.setState({
          erroPagamento: true,
          tentandoPagamento: false,
        });
        console.log("algo deu errado!", err);
      });
  };

  somenteIngresso = () => {
    clearTimeout(this.timeoutPagamento);

    this.timeoutPagamento = setTimeout(this.timeoutPagamento, 15000);

    const { tentandoPagamento } = this.state;
    if (tentandoPagamento) return false;

    this.setState({
      tentandoPagamento: true,
      erroPagamento: false,
    });

    this.submitCheckoutSomenteIngresso()
      .then(({ data }) => {
        clearTimeout(this.timeoutPagamento);

        console.log("data", data);
        if (data.success) {
          this.setState({
            tentandoPagamento: false,
            pedidoFinalizado: true,
            codigo: data.data,
          });

          const { limpaComprar } = this.props;
          limpaComprar();
        } else {
          clearTimeout(this.timeoutPagamento);

          if (data.data === "expirado") {
            console.log("possui produtos expirados, limpando carrinho");
            const { limpaComprar } = this.props;
            limpaComprar();
          }

          this.setState({
            erroPagamento: true,
            tentandoPagamento: false,
          });
        }
      })
      .catch((err) => {
        clearTimeout(this.timeoutPagamento);

        this.setState({
          erroPagamento: true,
          tentandoPagamento: false,
        });
        console.log("algo deu errado!", err);
      });
  };

  pedirCartao = (cartao) => {
    clearTimeout(this.timeoutPagamento);

    this.timeoutPagamento = setTimeout(this.timeoutPagamento, 15000);

    const { tentandoPagamento } = this.state;
    if (tentandoPagamento) return false;

    this.setState({
      tentandoPagamento: true,
      erroPagamento: false,
    });

    console.log(cartao);

    this.submitCheckoutCartao(cartao)
      .then(({ data }) => {
        clearTimeout(this.timeoutPagamento);

        console.log("data", data);
        if (data.success) {
          this.setState({
            tentandoPagamento: false,
            pedidoFinalizado: true,
            codigo: data.data,
          });

          const { limpaComprar } = this.props;
          limpaComprar();
        } else {
          clearTimeout(this.timeoutPagamento);

          if (data.data === "expirado") {
            console.log("possui produtos expirados, limpando carrinho");
            const { limpaComprar } = this.props;
            limpaComprar();
          }

          this.setState({
            erroPagamento: true,
            tentandoPagamento: false,
          });
        }
      })
      .catch((err) => {
        clearTimeout(this.timeoutPagamento);

        this.setState({
          erroPagamento: true,
          tentandoPagamento: false,
        });
        console.log("algo deu errado!", err);
      });
  };

  submitCheckoutBoleto = async (boleto) => {
    const {
      lead, carrinho, evento, ingressosQtd, evento_escolhido,
    } = this.props;
    const { naoTemHospedagem } = this.state;

    //formatando lead
    const leadKeys = Object.keys(lead.lead);
    const leadFormatado = {};
    leadKeys.forEach((leadKey) => {
      leadFormatado[`lead_${leadKey}`] = lead.lead[leadKey];
    });

    //formatando franquia
    const franquiaKeys = Object.keys(lead.franquia);
    const franquiaFormatado = {};
    franquiaKeys.forEach((franquiaKey) => {
      franquiaFormatado[`franquia_${franquiaKey}`] = lead.franquia[franquiaKey];
    });

    //formatando acompanhate
    const acompanhanteKeys = Object.keys(lead.acompanhante);
    const acompanhanteFormatado = {};

    console.log(naoTemHospedagem, ingressosQtd);

    if (!naoTemHospedagem && ingressosQtd === 1) {
      acompanhanteKeys.forEach((acompanhanteKey) => {
        acompanhanteFormatado[`acompanhante_${acompanhanteKey}`] = "";
      });

      console.log(acompanhanteFormatado);
    } else {
      acompanhanteKeys.forEach((acompanhanteKey) => {
        acompanhanteFormatado[`acompanhante_${acompanhanteKey}`] = lead.acompanhante[acompanhanteKey];
      });
    }

    const dados = {
      produtos: carrinho,
      evento_id: evento.id,
      meio_pagamento: "boleto",
      ...boleto,
      ...leadFormatado,
      ...franquiaFormatado,
      ...acompanhanteFormatado,
      evento_escolhido,
    };

    console.log("Enviando! 🔥", dados);

    try {
      const response = await client.post("/checkout", dados);
      return response;
    } catch (error) {
      return error;
    }
  };

  submitCheckoutSomenteIngresso = async () => {
    const {
      lead, carrinho, evento, ingressosQtd, evento_escolhido,
    } = this.props;
    const { naoTemHospedagem } = this.state;

    //formatando lead
    const leadKeys = Object.keys(lead.lead);
    const leadFormatado = {};
    leadKeys.forEach((leadKey) => {
      leadFormatado[`lead_${leadKey}`] = lead.lead[leadKey];
    });

    //formatando franquia
    const franquiaKeys = Object.keys(lead.franquia);
    const franquiaFormatado = {};
    franquiaKeys.forEach((franquiaKey) => {
      franquiaFormatado[`franquia_${franquiaKey}`] = lead.franquia[franquiaKey];
    });

    //formatando acompanhate
    const acompanhanteKeys = Object.keys(lead.acompanhante);
    const acompanhanteFormatado = {};

    console.log(naoTemHospedagem, ingressosQtd);

    if (!naoTemHospedagem && ingressosQtd === 1) {
      acompanhanteKeys.forEach((acompanhanteKey) => {
        acompanhanteFormatado[`acompanhante_${acompanhanteKey}`] = "";
      });

      console.log(acompanhanteFormatado);
    } else {
      acompanhanteKeys.forEach((acompanhanteKey) => {
        acompanhanteFormatado[`acompanhante_${acompanhanteKey}`] = lead.acompanhante[acompanhanteKey];
      });
    }

    const dados = {
      produtos: carrinho,
      evento_id: evento.id,
      meio_pagamento: "sem-pagamento",
      ...leadFormatado,
      ...franquiaFormatado,
      ...acompanhanteFormatado,
      evento_escolhido,
    };

    console.log("Enviando! 🔥", dados);

    try {
      const response = await client.post("/checkout", dados);
      return response;
    } catch (error) {
      return error;
    }
  };

  submitCheckoutCartao = async (cartao) => {
    const {
      lead, carrinho, evento, ingressosQtd, evento_escolhido,
    } = this.props;
    const { naoTemHospedagem } = this.state;

    //formatando lead
    const leadKeys = Object.keys(lead.lead);
    const leadFormatado = {};
    leadKeys.forEach((leadKey) => {
      leadFormatado[`lead_${leadKey}`] = lead.lead[leadKey];
    });

    //formatando franquia
    const franquiaKeys = Object.keys(lead.franquia);
    const franquiaFormatado = {};
    franquiaKeys.forEach((franquiaKey) => {
      franquiaFormatado[`franquia_${franquiaKey}`] = lead.franquia[franquiaKey];
    });

    //formatando acompanhate
    const acompanhanteKeys = Object.keys(lead.acompanhante);
    const acompanhanteFormatado = {};

    console.log(naoTemHospedagem, ingressosQtd);

    if (!naoTemHospedagem && ingressosQtd === 1) {
      acompanhanteKeys.forEach((acompanhanteKey) => {
        acompanhanteFormatado[`acompanhante_${acompanhanteKey}`] = "";
      });

      console.log(acompanhanteFormatado);
    } else {
      acompanhanteKeys.forEach((acompanhanteKey) => {
        acompanhanteFormatado[`acompanhante_${acompanhanteKey}`] = lead.acompanhante[acompanhanteKey];
      });
    }

    const dados = {
      produtos: carrinho,
      evento_escolhido,
      evento_id: evento.id,
      meio_pagamento: "cartao",
      ...cartao,
      ...leadFormatado,
      ...franquiaFormatado,
      ...acompanhanteFormatado,
    };

    console.log("Enviando! 🔥", dados);

    try {
      const response = await client.post("/checkout", dados);
      return response;
    } catch (error) {
      return error;
    }
  };

  escolherIngressosEIrParaCheckout = () => {
    const { marcaIngressosEscolhidos, carrinho } = this.props;

    if (!carrinho.length) {
      console.log("carrinho vazio...");
    }

    marcaIngressosEscolhidos();

    this.setState({
      irEventoParaCheckout: true,
    });
    return true;
  };

  //middle man para o redux -- podem ser feitas validacoes aqui
  adicionarIngressoAoCarrinho = (produtoId) => {
    const { atualizaCarrinho, carrinho } = this.props;
    const { ingressosNoCarrinho } = this.state;

    atualizaCarrinho(produtoId);

    // const carrinhoIngressos = carrinho && carrinho.length > 0;

    this.setState({ ingressosNoCarrinho: ingressosNoCarrinho + 1 });
  };

  removerIngressoAoCarrinho = (produtoId) => {
    const { removeCarrinho } = this.props;

    removeCarrinho(produtoId);

    this.setState({ ingressosNoCarrinho: 0 });
  };

  //middle man para o redux
  adicionarHospedagemAoCarrinho = (dias) => {
    const { atualizaHospedagemNoCarrinho } = this.props;

    atualizaHospedagemNoCarrinho(dias);
  };

  timeoutPagamento;

  render() {
    const {
      evento: { produtos, path },
      eventoCarregado,
    } = this.props;
    const {
      irEventoParaCheckout, pedidoFinalizado, codigo, tentandoPagamento, erroPagamento, ingressosNoCarrinho, naoTemHospedagem,
    } = this.state;

    if (pedidoFinalizado) return <Redirect to={`/${path}/checkout/sucesso/odonto2020-${codigo}`} />;

    //verifica se tem que trocar para a pagina de checkout
    if (irEventoParaCheckout) {
      return (
        <Redirect
          to={{
            pathname: `/${path}/checkout`,
          }}
        />
      );
    }

    //se o evento nao estiver carregado nao mostra nada
    if (!eventoCarregado) return <div className="comprar" />;

    const {
      carrinho,
      ingressosQtd,
      comprarCompleto,
      evento,
      ingressosEscolhidos,
      hospedagemEscolhida,
      dadosPessoaisPreenchidos,
      dadosPagamentoPreenchidos,
      atualizaLead,
      atualizaEvento,
      removeHospedagemNoCarrinho,
      lead,
      meioPagamento,
      atualizaMeioPagamento,
      limpaCarrinho,
    } = this.props;
    const produtosNoCarrinho = carrinho.map(item => item.id);

    //se o meio de pagamento for cartao vamos usar os valores de cartao
    const ehCartao = meioPagamento === "cartao";

    const subTotalIngressos = produtos
      && produtos.reduce(
        (soma, produto) => (produtosNoCarrinho.includes(produto.id) && produto.produto_tipo_id === 1
          ? soma + (ehCartao ? produto.valor_cartao : produto.valor)
          : soma),
        0,
      );

    const subtotal = (produtosNoCarrinho.length
        && produtosNoCarrinho.reduce((soma, produtoNoCarrinho) => {
          const produtoDados = produtos.find(produto => produto.id === produtoNoCarrinho);
          const total = produtoDados ? soma + (ehCartao ? produtoDados.valor_cartao : produtoDados.valor) : soma + 0;

          return total;
        }, 0))
      || 0;

    const subtotalParcelado = (produtosNoCarrinho.length
        && produtosNoCarrinho.reduce((soma, produtoNoCarrinho) => {
          const produtoDados = produtos.find(produto => produto.id === produtoNoCarrinho);
          const total = produtoDados ? soma + produtoDados.valor : soma + 0;

          return total;
        }, 0))
      || 0;

    const subtotalParceladoCartao = (produtosNoCarrinho.length
      && produtosNoCarrinho.reduce((soma, produtoNoCarrinho) => {
        const produtoDados = produtos.find(produto => produto.id === produtoNoCarrinho);
        const total = produtoDados ? soma + produtoDados.valor : soma + 0;

        return total;
      }, 0))
      || 0;

    // console.log('valorParcelado Cartão', subtotalParceladoCartao / 6);

    //const taxaTotal = subtotal ? subtotal / (subtotal * 0.95) : 0;
    //const taxa = meioPagamento === "cartao" ? subtotal * 0.053 : 0;
    const taxa = 0;
    const total = subtotal + taxa;
    const totalComAjusteParcelado = subtotalParcelado;

    const ingressos = produtos.filter(produto => produto.produto_tipo_id === 1);
    const hospedagens = produtos.filter(produto => produto.produto_tipo_id === 2);

    return (
      <div className={classNames("comprar", { "comprar--completo": comprarCompleto })}>
        {comprarCompleto && <ComprarHeader evento={evento} />}
        <div className="comprar__container container">
          <div className="comprar__progresso-wrapper">
            {!pedidoFinalizado && (
              <Progresso
                ingressosEscolhidos={ingressosEscolhidos}
                hospedagemEscolhida={hospedagemEscolhida}
                dadosPessoaisPreenchidos={dadosPessoaisPreenchidos}
                dadosPagamentoPreenchidos={dadosPagamentoPreenchidos}
                subtotal={subtotal}
                taxa={taxa}
                total={total}
                comprarCompleto={comprarCompleto}
                limpaCarrinho={limpaCarrinho}
              />
            )}
          </div>
          {produtos && produtos.length && (
            <div className="comprar__conteudo-wrapper">
              <Ingressos
                adicionarIngressoAoCarrinho={this.adicionarIngressoAoCarrinho}
                removerIngressoAoCarrinho={this.removerIngressoAoCarrinho}
                ingressos={ingressos}
                produtosNoCarrinho={produtosNoCarrinho}
                escolherIngressosEIrParaCheckout={this.escolherIngressosEIrParaCheckout}
                subTotal={subTotalIngressos}
                comprarCompleto={comprarCompleto}
                carrinho={carrinho}
                ingressosNoCarrinho={ingressosNoCarrinho}
                expandido
                ingressosQtd={ingressosQtd}
              />
              {/* {comprarCompleto && (
                <DadosEvento
                  atualizaEvento={atualizaEvento}
                />
              )} */}
              {comprarCompleto && (
                <Hospedagem
                  de={evento.hospedagem_de}
                  ate={evento.hospedagem_ate}
                  hospedagens={hospedagens}
                  adicionarHospedagemAoCarrinho={this.adicionarHospedagemAoCarrinho}
                  removeHospedagemNoCarrinho={removeHospedagemNoCarrinho}
                  precisaDeHotel={this.precisaDeHotel}
                />
              )}
              {comprarCompleto && (
                <DadosPessoais
                  lead={lead}
                  dadosPessoaisPreenchidos={dadosPessoaisPreenchidos}
                  atualizaLead={atualizaLead}
                  hotel={naoTemHospedagem}
                  ingressosQtd={ingressosQtd}
                />
              )}
              {comprarCompleto && (
                <DadosPagamento
                  atualizaMeioPagamento={atualizaMeioPagamento}
                  meioPagamento={meioPagamento}
                  subtotal={subtotal}
                  taxa={taxa}
                  total={total}
                  totalComAjusteParcelado={totalComAjusteParcelado}
                  produtos={produtos}
                  carrinho={carrinho}
                  produtosNoCarrinho={produtosNoCarrinho}
                  pedirBoleto={this.pedirBoleto}
                  pedirCartao={this.pedirCartao}
                  somenteIngresso={this.somenteIngresso}
                  erroPagamento={erroPagamento}
                  limpaCarrinho={limpaCarrinho}
                  parcelasBoleto={subtotalParcelado / 2}
                  totalCartao={subtotalParceladoCartao}
                />
              )}
            </div>
          )}
        </div>

        <div className={classNames("modal", "modal-tentando", { "is-active": tentandoPagamento })}>
          <div className="modal-background" />
          <div className="modal-content">
            <div className="spinner">
              <div className="rect1" />
              <div className="rect2" />
              <div className="rect3" />
              <div className="rect4" />
              <div className="rect5" />
            </div>
            <span className="modal-tentando__texto">Processando pedido...</span>
          </div>
        </div>
      </div>
    );
  }
}

Comprar.propTypes = {
  evento: PropTypes.object.isRequired,
  carrinho: PropTypes.array.isRequired,
  lead: PropTypes.object,
  meioPagamento: PropTypes.string,
  atualizaCarrinho: PropTypes.func.isRequired,
  removeCarrinho: PropTypes.func.isRequired,
  limpaComprar: PropTypes.func.isRequired,
  limpaCarrinho: PropTypes.func.isRequired,
  atualizaMeioPagamento: PropTypes.func.isRequired,
  atualizaHospedagemNoCarrinho: PropTypes.func.isRequired,
  removeHospedagemNoCarrinho: PropTypes.func.isRequired,
  atualizaLead: PropTypes.func.isRequired,
  atualizaEvento: PropTypes.func.isRequired,
  marcaIngressosEscolhidos: PropTypes.func.isRequired,
  eventoCarregado: PropTypes.bool.isRequired,
  comprarCompleto: PropTypes.bool,
  ingressosEscolhidos: PropTypes.bool,
  hospedagemEscolhida: PropTypes.bool,
  dadosPessoaisPreenchidos: PropTypes.bool,
  dadosPagamentoPreenchidos: PropTypes.bool,
};

Comprar.defaultProps = {
  comprarCompleto: false,
  ingressosEscolhidos: false,
  hospedagemEscolhida: false,
  dadosPessoaisPreenchidos: false,
  dadosPagamentoPreenchidos: false,
  lead: {},
  meioPagamento: "cartao",
};

const mapStateToProps = state => ({
  evento: state.evento.evento,
  eventoCarregado: state.evento.eventoCarregado,
  lead: state.comprar.lead,
  evento_escolhido: state.comprar.evento_escolhido,
  carrinho: state.comprar.carrinho,
  ingressosQtd: state.comprar.ingressos,
  meioPagamento: state.comprar.meioPagamento,
  ingressosEscolhidos: state.comprar.ingressosEscolhidos,
  hospedagemEscolhida: state.comprar.hospedagemEscolhida,
  dadosPessoaisPreenchidos: state.comprar.dadosPessoaisPreenchidos,
  dadosPagamentoPreenchidos: state.comprar.dadosPagamentoPreenchidos,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    atualizaCarrinho: atualizaCarrinhoAction,
    removeCarrinho: removeCarrinhoAction,
    atualizaMeioPagamento: atualizaMeioPagamentoAction,
    atualizaHospedagemNoCarrinho: atualizaHospedagemNoCarrinhoAction,
    removeHospedagemNoCarrinho: removeHospedagemNoCarrinhoAction,
    marcaIngressosEscolhidos: ingressosEscolhidosAction,
    atualizaLead: atualizaLeadAction,
    limpaComprar: limpaComprarAction,
    limpaCarrinho: limpaCarrinhoAction,
    atualizaEvento: atualizaEventoAction,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Comprar);
