import axios from "axios";

const fetchClient = () => {
  const defaultOptions = {
    baseURL: process.env.REACT_APP_API_URL,
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
  };
  // Create instance
  const instance = axios.create(defaultOptions);

  return instance;
};

export default fetchClient();
