import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import moment from "moment";

import DatePicker from "./DatePicker";

class Hospedagem extends React.Component {
  state = {
    qualidadeSelecionada: "Normal",
    tamanhoSelecionado: "Double",
    camasSelecionadas: "",
    startDate: null,
    startDateFormatada: "",
    endDate: null,
    endDateFormatada: "",
    naoPrecisoDeHotel: false,
    hotelSelecionado: 4,
  };

  componentDidUpdate(prevProps, prevState) {
    //vamos verificar se o evento realmente foi alterado e se vale a pena mandar a escolha para cima
    const {
      qualidadeSelecionada: qualidadeSelecionadaPrev,
      tamanhoSelecionado: tamanhoSelecionadoPrev,
      camasSelecionadas: camasSelecionadasPrev,
      startDateFormatada: startDateFormatadaPrev,
      endDateFormatada: endDateFormatadaPrev,
    } = prevState;

    const {
      qualidadeSelecionada,
      tamanhoSelecionado,
      camasSelecionadas,
      startDateFormatada,
      endDateFormatada,
      startDate,
      endDate,
    } = this.state;

    //vamos verificar se esta tudo preenchido
    if (
      qualidadeSelecionada !== ""
      && tamanhoSelecionado !== ""
      && camasSelecionadas !== ""
      && startDateFormatada !== ""
      && endDateFormatada !== ""
      && startDate
      && endDate
    ) {
      console.log("hospedagem preenchida ✅");
      //agora vamos verificar se a hospedagem foi alterada e se vale realmente a pena mandar para cima
      if (
        qualidadeSelecionada === qualidadeSelecionadaPrev
        && tamanhoSelecionado === tamanhoSelecionadoPrev
        && camasSelecionadas === camasSelecionadasPrev
        && startDateFormatada === startDateFormatadaPrev
        && endDateFormatada === endDateFormatadaPrev
      ) {
        console.log("tudo igual... não enviando para a store");
      } else {
        const { adicionarHospedagemAoCarrinho } = this.props;
        const dias = this.hospedagemEscolhida();
        adicionarHospedagemAoCarrinho(dias);
      }
    }
  }

  selecionaQualidade = (qualidade) => {
    this.setState({ qualidadeSelecionada: qualidade });
  };

  selecionaTamanho = (tamanho) => {
    //vamos pre-preencher as camas
    let camasSelecionadas = "";
    if (tamanho !== "") {
      switch (tamanho) {
      case "Single":
        camasSelecionadas = "1 cama de Casal";
        break;

      case "Double":
        camasSelecionadas = "2 camas de Casal";
        break;

        // case "Triplo":
        //   camasSelecionadas = "1 casal + 1 solteiro";
        //   break;

      default:
        camasSelecionadas = "";
        break;
      }
    }

    this.setState({ tamanhoSelecionado: tamanho, camasSelecionadas });
  };

  selecionaCamas = (camas) => {
    this.setState({ camasSelecionadas: camas });
  };

  naoPrecisoDeHotel = () => {
    const { removeHospedagemNoCarrinho } = this.props;
    removeHospedagemNoCarrinho();

    this.props.precisaDeHotel();

    this.setState({
      naoPrecisoDeHotel: true,
    });
  };

  precisoDeHotel = () => {
    this.props.precisaDeHotel();

    this.setState({
      naoPrecisoDeHotel: false,
    });
  };

  //o que vem aqui tem a instancia moment
  onDatesChange = ({ startDate, endDate }) => {
    this.setState({
      startDate,
      endDate,
      startDateFormatada: startDate && startDate.format("YYYY-MM-DD"),
      endDateFormatada: endDate && endDate.format("YYYY-MM-DD"),
    });
  };

  //retorna os dias escolhidos com o valor embutido
  hospedagemEscolhida = () => {
    const { hospedagens: hospedagensProdutos } = this.props;
    const {
      qualidadeSelecionada,
      tamanhoSelecionado,
      camasSelecionadas,
      startDate,
      endDate,
      hotelSelecionado,
    } = this.state;

    const quarto = hospedagensProdutos.filter(
      ({ descricao }) => descricao === `${hotelSelecionado} Hospedagem ${qualidadeSelecionada} ${tamanhoSelecionado}`,
    );

    const dias = [];
    const dataAuxiliar = moment(startDate);
    while (dataAuxiliar.format("YYYY-MM-DD") < endDate.format("YYYY-MM-DD")) {
      dias.push({
        id: quarto[0].id,
        valor: quarto[0].valor,
        observacao: `dia ${dataAuxiliar.format("YYYY-MM-DD")} ao posterior -- ${camasSelecionadas}`,
      });

      dataAuxiliar.add(1, "days");
    }

    return dias;
  };

  atualizaHotel = (hotelSelecionado) => {
    this.setState({
      hotelSelecionado,
      qualidadeSelecionada: "Normal",
      tamanhoSelecionado: "",
      camasSelecionadas: "",
    });
  };

  render() {
    const {
      hospedagens: hospedagensProdutos, de, ate, precisaDeHotel,
    } = this.props;
    const {
      qualidadeSelecionada,
      tamanhoSelecionado,
      camasSelecionadas,
      startDate,
      endDate,
      naoPrecisoDeHotel,
      hotelSelecionado,
    } = this.state;

    console.log(hospedagensProdutos);

    const quartos = hospedagensProdutos
      .filter(hospedagemProduto => hospedagemProduto.hospedagem.hotel_id === hotelSelecionado)
      .map(hospedagemProduto => hospedagemProduto.hospedagem);

    console.log(quartos);

    const quartosQualidades = quartos.reduce(
      (acumulador, { qualidade }) => (acumulador.includes(qualidade) ? acumulador : [...acumulador, qualidade]),
      [],
    );
    const quartosTamanhos = quartos.reduce(
      (acumulador, { tamanho }) => (acumulador.includes(tamanho) ? acumulador : [...acumulador, tamanho]),
      [],
    );

    let camas = [];
    if (tamanhoSelecionado !== "") {
      switch (tamanhoSelecionado) {
      case "Single":
        camas = ["1 cama solteiro"];
        break;

      case "Double":
        camas = ["1 cama casal", "2 camas solteiro"];
        break;

      case "Triplo":
        camas = ["1 casal + 1 solteiro", "3 camas solteiro"];
        break;

      default:
        camas = [];
        break;
      }
    }

    let subTotal = 0;
    if (qualidadeSelecionada !== "" && tamanhoSelecionado !== "" && camasSelecionadas !== "" && startDate && endDate) {
      subTotal = this.hospedagemEscolhida().reduce((total, { valor }) => total + valor, 0) || 0;

      // console.log(this.hospedagemEscolhida());

      // console.log(subTotal.toString().replace(".", ","));
    }

    return (
      <div className="hospedagens">
        <div className="hospedagens__topo">
          <span className="hospedagens__titulo titulo-4">Hospedagens</span>
          {/* <p className="hospedagens__subtitulo">
            Você pode pular essa etapa caso prefira não ficar hospedado no hotel durante o evento.
          </p> */}
          <div className="hospedagens__datepicker-wrapper">
            <span className="subtitulo-2">Selecione as datas de check-in e check-out</span>
            <div className="hospedagens__datepicker-input-wrapper">
              {!naoPrecisoDeHotel && (
                <DatePicker
                  onDatesChange={this.onDatesChange}
                  startDate={startDate}
                  endDate={endDate}
                  modificadorId="evento-hospedagem"
                  de={de}
                  ate={ate}
                />
              )}
              {/* {!naoPrecisoDeHotel && (
                <button onClick={this.naoPrecisoDeHotel} className="hospedagens__nao-preciso" type="button">
                  Não preciso de hotel
                </button>
              )}
              {naoPrecisoDeHotel && (
                <button onClick={this.precisoDeHotel} className="hospedagens__nao-preciso botao-2" type="button">
                  Preciso de hotel!
                </button>
              )} */}
            </div>
          </div>
          {!naoPrecisoDeHotel && (
            <div className="hospedagens__hoteis">
              <span className="subtitulo-2">Maksoud Plaza Hotel</span>
              {/* <div className="dados-pagamento__meios">
                <button
                  className={classNames("botao-2", "dados-pagamento__meio-button", {
                    "dados-pagamento__meio-button--ativo": hotelSelecionado === 4,
                  })}
                  type="button"
                  onClick={() => this.atualizaHotel(4)}
                >
                  Maksoud Plaza Hotel
                </button>
              </div> */}
              <p className="hospedagens__hotel-endereco">
                {hotelSelecionado === 4
                  ? "Rua São Carlos do Pinhal, 424 - Bela Vista. São Paulo/SP"
                  : ""}
              </p>
              {hotelSelecionado === 2 && (
                <p className="hospedagens__hotel-aviso">
                  A hospedagem neste hotel não dá acesso a nenhuma área de lazer do resort, apenas às salas do evento.
                </p>
              )}
            </div>
          )}
          {!naoPrecisoDeHotel && (
            <div className="hospedagens__quartos-wrapper">
              <div className="quartos">
                {/* <div className="quartos__opcoes">
                  <div className="quartos__opcoes-topo">
                    <span className="quartos__opcoes-titulo subtitulo-2 subtitulo-2--uppercase">Apartamento</span>
                  </div>
                  <div className="quartos__opcoes-lista">
                    {quartosQualidades
                      && quartosQualidades.map(quartoQualidade => (
                        <div
                          key={quartoQualidade}
                          className={classNames("quartos__opcao", {
                            "quartos__opcao--selecionado": quartoQualidade === qualidadeSelecionada,
                          })}
                          onClick={() => this.selecionaQualidade(quartoQualidade)}
                          role="option"
                          aria-selected={quartoQualidade}
                          tabIndex={0}
                        >
                          <span className="quartos__opcao-titulo">{quartoQualidade}</span>
                        </div>
                      ))}
                  </div>
                </div> */}
                <div
                  className={classNames("quartos__opcoes", {
                    "quartos__opcoes--desativado": qualidadeSelecionada === "",
                  })}
                >
                  {/* <div className="quartos__opcoes-topo">
                    <span className="quartos__opcoes-titulo subtitulo-2 subtitulo-2--uppercase">
                      Tipo de Acomodação
                    </span>
                    <span className="quartos__opcoes-dica">Selecione um tipo de apartamento antes</span>
                  </div> */}
                  {/* <div className="quartos__opcoes-lista">
                    {quartosTamanhos
                      && quartosTamanhos.map((quartoTamanho, index) => {
                        console.log(hotelSelecionado, qualidadeSelecionada, quartoTamanho, hospedagensProdutos);
                        const valor = qualidadeSelecionada !== ""
                          ? hospedagensProdutos.filter(
                            ({ descricao }) => descricao
                                  === `${hotelSelecionado} Hospedagem ${qualidadeSelecionada} ${quartoTamanho}`,
                          ).valor
                          : 0;

                        return (
                          <div
                            key={quartoTamanho}
                            className={classNames("quartos__opcao", {
                              "quartos__opcao--selecionado": quartoTamanho === tamanhoSelecionado,
                            })}
                            onClick={() => this.selecionaTamanho(quartoTamanho)}
                            role="option"
                            aria-selected={quartoTamanho}
                            tabIndex={0}
                          >
                            <span className="quartos__opcao-titulo">
                              {quartoTamanho}{" "}
                              <span className="quartos__opcao-detalhe quartos__opcao-detalhe--uppercase">
                                {`${index + 1} ${index + 1 > 1 ? "pessoas" : "pessoa"}`}
                              </span>
                            </span>
                            {valor > 0 && (
                              <span className="quartos__opcao-valor">
                                {`R$ ${valor},00`} <span className="quartos__opcao-detalhe">Diária</span>
                              </span>
                            )}
                          </div>
                        );
                      })}
                  </div> */}
                </div>
                <div
                  className={classNames("quartos__opcoes", {
                    "quartos__opcoes--desativado": tamanhoSelecionado === "",
                  })}
                >
                  <div className="quartos__opcoes-topo">
                    <span className="quartos__opcoes-titulo subtitulo-2 subtitulo-2--uppercase">Opções de Camas</span>
                    <span className="quartos__opcoes-dica">Selecione um tamanho de apartamento antes</span>
                  </div>
                  <div className="quartos__opcoes-lista">
                    {camas
                      && camas.map(cama => (
                        <div
                          key={cama}
                          className={classNames("quartos__opcao", {
                            "quartos__opcao--selecionado": cama === camasSelecionadas,
                          })}
                          onClick={() => this.selecionaCamas(cama)}
                          role="option"
                          aria-selected={cama}
                          tabIndex={0}
                        >
                          <span className="quartos__opcao-titulo">{cama}</span>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          )}
          {subTotal > 0 && !naoPrecisoDeHotel && (
            <div className="comprar__subtotal">
              <span className="comprar__subtotal-texto">Subtotal:</span>
              <span className="comprar__subtotal-valor">{`R$ ${subTotal.toString().replace(".", ",")}`}</span>
            </div>
          )}
        </div>
      </div>
    );
  }
}

Hospedagem.propTypes = {
  hospedagens: PropTypes.array.isRequired,
  adicionarHospedagemAoCarrinho: PropTypes.func.isRequired,
  removeHospedagemNoCarrinho: PropTypes.func.isRequired,
  de: PropTypes.string.isRequired,
  ate: PropTypes.string.isRequired,
};

export default Hospedagem;
