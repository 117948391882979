import client from "../../client";

/**
 * busca as opcoes de destino e etc das perguntas
 * @param {string} eventoPath identificador do evento
 */
export const buscaOpcoes = async (eventoPath) => {
  try {
    const {
      data: { success, data: opcoes },
    } = await client.get(`/eventos/${eventoPath}/perguntas/opcoes`);
    if (success) {
      return opcoes;
    }
    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

/**
 * busca todas as perguntas de um evento
 * @param {string} eventoPath identificador do evento
 */
export const buscaPerguntas = async (eventoPath) => {
  try {
    const {
      data: { success, data: perguntas },
    } = await client.get(`/eventos/${eventoPath}/perguntas`);
    if (success) {
      return perguntas;
    }
    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

/**
 * busca todas as perguntas de um evento
 * @param {string} eventoPath identificador do evento
 * @param {integer} perguntaId identificador da pergunta
 */
export const togglePerguntaRespondida = async (eventoPath, perguntaId) => {
  try {
    const {
      data: { success, data: pergunta },
    } = await client.post(`/eventos/${eventoPath}/perguntas/${perguntaId}/toggle-respondida`);
    if (success) {
      return pergunta;
    }
    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

/**
 * cria um nova pergunta para o evento
 * @param {string} eventoPath identificador do evento
 */
export const enviaPergunta = async (eventoPath, pergunta) => {
  try {
    const {
      data: { success, data: perguntaCriada },
    } = await client.post(`/eventos/${eventoPath}/perguntas`, {
      ...pergunta,
    });
    if (success) {
      return perguntaCriada;
    }
    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};
