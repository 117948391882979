import client from "../../client";

/**
 * busca todas os 'aceites' de um evento
 * @param {string} eventoPath identificador do evento
 */
export const buscaAceitesFeitos = async (eventoPath) => {
  try {
    const {
      data: { success, data: aceitesFeitos },
    } = await client.get(`/eventos/${eventoPath}/aceites`);
    if (success) {
      return aceitesFeitos;
    }
    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};
