import React, { Fragment } from "react";
import PropTypes from "prop-types";
import ReactVimeo from '@u-wave/react-vimeo';

class Descricao extends React.Component {
  state = {
    videoTerminou: false,
  };

  componentDidMount() {
    if (window.location.hash === '#video') {
      setTimeout(() => {
        const top = document.querySelector("#video").offsetTop;
        window.scroll({
          top: top + 500,
          left: 0,
          behavior: "smooth",
        });
      }, 2000);
    }
  }

  onVideoTerminou = () => this.setState({ videoTerminou: true });

  scrollInscricao = () => {
    const top = document.querySelector(".comprar").offsetTop;
    window.scroll({
      top,
      left: 0,
      behavior: "smooth",
    });
  };

  render() {
    const { descricao, titulo } = this.props;
    const { videoTerminou } = this.state;

    //separa o texto com base na keyword ::
    const descricaoEmParagrafos = descricao.split("::");

    return (
      <Fragment>
        <div className="descricao">
          <h2 className="titulo-4 titulo-4--grande descricao__titulo">{titulo}</h2>
          <div className="descricao__texto">
            {descricaoEmParagrafos.map((pedaco, key) => (
              <p key={key}>
                {pedaco}
              </p>
            ))}
          </div>
        </div>
        <div className="descricao-media__com-imagem">
          <img className="descricao-media__com-imagem__imagem" src="https://eventosapi.p9.digital/storage/eventos/odontocompany/convencao-nacional-franqueados-2019/img/surpreendase.png" alt="Surpreenda-se" />
        </div>
        <div className="descricao-media__com-video">
          <ReactVimeo
            video={374900160}
            className="descricao-media__com-video__vimeo-player"
            title={titulo}
            allowFullScreen
            responsive
            // autoplay
          />
        </div>
      </Fragment>
    );
  }
}

Descricao.propTypes = {
  descricao: PropTypes.string.isRequired,
  titulo: PropTypes.string.isRequired,
};

export default Descricao;
