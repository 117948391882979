import React from "react";
import PropTypes from "prop-types";
import MaskedInput from "react-maskedinput";

class DadosPessoais extends React.Component {
  constructor(props) {
    super(props);

    const { lead } = props;

    const defaultLead = {
      lead: {
        nome: "",
        cpf: "",
        rg: "",
        nascimento: "",
        celular: "",
        email: "",
        ...lead.lead,
      },
      franquia: {
        cnpj: "",
        razao_social: "",
        quantidade_pdvs: "",
        // centro_custos: "",
        telefone: "",
        endereco: "",
        cidade: "",
        estado: "",
        cep: "",
        centro_custos: "",
        // consultor: "",
        ...lead.franquia,
      },
      acompanhante: {
        nome: "",
        rg: "",
        ...lead.acompanhante,
      },
    };

    this.state = {
      ...defaultLead,
      estados: [
        "AC",
        "AL",
        "AM",
        "AP",
        "BA",
        "CE",
        "DF",
        "ES",
        "GO",
        "MA",
        "MG",
        "MS",
        "MT",
        "PA",
        "PB",
        "PE",
        "PI",
        "PR",
        "RJ",
        "RN",
        "RO",
        "RR",
        "RS",
        "SC",
        "SE",
        "SP",
        "TO",
      ],
    };
  }

  verificaSeTudoEstaPreenchido = () => {
    const { lead, franquia, acompanhante } = this.state;

    const camposLeads = Object.keys(lead);
    const camposFranquia = Object.keys(franquia);
    // const camposAcompanhante = Object.keys(acompanhante);


    let leadOk = true;
    let franquiaOk = true;
    const acompanhanteOk = true;

    camposLeads.forEach((campo) => {
      if (!lead[campo] || lead[campo] === "") {
        leadOk = false;
      }
    });
    camposFranquia.forEach((campo) => {
      if (!franquia[campo] || franquia[campo] === "") {
        franquiaOk = false;
      }
    });

    const tudoPreenchido = leadOk && franquiaOk && acompanhanteOk;

    const { atualizaLead } = this.props;
    atualizaLead(tudoPreenchido, { lead, franquia, acompanhante });
  };

  handleLeadInput = (event) => {
    const { lead } = this.state;
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    lead[name] = value;

    this.setState({
      lead,
    });
  };

  handleFranquiaInput = (event) => {
    const { franquia } = this.state;
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    franquia[name] = value;

    this.setState({
      franquia,
    });
  };

  handleAcompanhante = (event) => {
    const { acompanhante } = this.state;
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    acompanhante[name] = value;

    this.setState({
      acompanhante,
    });
  };

  render() {
    const {
      lead, franquia, acompanhante, estados,
    } = this.state;

    const {
      hotel,
      ingressosQtd,
    } = this.props;

    let titulo = '';
    let acompanhamento = true;

    if (hotel && ingressosQtd > 1) {
      titulo = ' (hospedagem + inscrição)';
    } else if (!hotel && ingressosQtd > 1) {
      titulo = ' (apenas inscrição)';
    } else if (!hotel && ingressosQtd === 1) {
      acompanhamento = false;
    } else if (hotel && ingressosQtd <= 1) {
      titulo = ' (apenas hospedagem)';
    } else if (hotel && ingressosQtd === 0) {
      titulo = ' (apenas hospedagem)';
    } else if (!hotel && ingressosQtd === 0) {
      acompanhamento = false;
    }

    return (
      <div className="dados-pessoais">
        <span className="dados-pessoais__titulo titulo-4">Dados Pessoais</span>
        <div className="dados-pessoais__inputs">
          <div className="dados-pessoas__input-row">
            <div className="dados-pessoais__input-wrapper">
              <input
                type="text"
                placeholder="Nome completo"
                name="nome"
                value={lead.nome}
                onChange={this.handleLeadInput}
                onBlur={this.verificaSeTudoEstaPreenchido}
                className="dados-pessoais__input"
              />
            </div>
          </div>
          <div className="dados-pessoais__input-row dados-pessoais__input-row--multiple">
            <div className="dados-pessoais__input-wrapper">
              <MaskedInput
                type="text"
                placeholder="CPF"
                name="cpf"
                mask="111.111.111-11"
                size="14"
                value={lead.cpf}
                onChange={this.handleLeadInput}
                onBlur={this.verificaSeTudoEstaPreenchido}
                className="dados-pessoais__input"
              />
            </div>
            <div className="dados-pessoais__input-wrapper">
              <input
                type="text"
                placeholder="RG"
                name="rg"
                value={lead.rg}
                onChange={this.handleLeadInput}
                onBlur={this.verificaSeTudoEstaPreenchido}
                className="dados-pessoais__input"
              />
            </div>
            <div className="dados-pessoais__input-wrapper">
              <MaskedInput
                type="text"
                placeholder="Nascimento"
                mask="11/11/1111"
                size="10"
                name="nascimento"
                value={lead.nascimento}
                onChange={this.handleLeadInput}
                onBlur={this.verificaSeTudoEstaPreenchido}
                className="dados-pessoais__input"
              />
            </div>
          </div>
          {/* <div className="dados-pessoas__input-row">
            <div className="dados-pessoais__input-wrapper">
              <input
                type="text"
                placeholder="Endereço"
                name="endereco"
                value={lead.endereco}
                onChange={this.handleLeadInput}
                onBlur={this.verificaSeTudoEstaPreenchido}
                className="dados-pessoais__input"
              />
            </div>
          </div>
          <div className="dados-pessoais__input-row dados-pessoais__input-row--multiple">
            <div className="dados-pessoais__input-wrapper">
              <input
                type="text"
                placeholder="Cidade"
                name="cidade"
                value={lead.cidade}
                onChange={this.handleLeadInput}
                onBlur={this.verificaSeTudoEstaPreenchido}
                className="dados-pessoais__input"
              />
            </div>
            <div className="dados-pessoais__input-wrapper">
              <MaskedInput
                mask="(11) 1111-1111"
                type="text"
                placeholder="Tel residencial"
                name="telefone"
                value={lead.telefone}
                onChange={this.handleLeadInput}
                onBlur={this.verificaSeTudoEstaPreenchido}
                className="dados-pessoais__input"
              />
              </div>
          </div>
            */}

          <div className="dados-pessoas__input-row">
            <div className="dados-pessoais__input-wrapper">
              <input
                type="text"
                placeholder="Email"
                name="email"
                value={lead.email}
                onChange={this.handleLeadInput}
                onBlur={this.verificaSeTudoEstaPreenchido}
                className="dados-pessoais__input"
              />
            </div>
            <div className="dados-pessoais__input-wrapper">
              <MaskedInput
                mask="(11) 11111-1111"
                type="text"
                placeholder="Celular"
                name="celular"
                value={lead.celular}
                onChange={this.handleLeadInput}
                onBlur={this.verificaSeTudoEstaPreenchido}
                className="dados-pessoais__input"
              />
            </div>
          </div>
        </div>

        {acompanhamento && (
          <>
            <span className="dados-pessoais__titulo titulo-4">{`Dados do Acompanhante${titulo}`}</span>
            <div className="dados-pessoais__inputs">
              <div className="dados-pessoas__input-row">
                <div className="dados-pessoais__input-wrapper">
                  <input
                    type="text"
                    placeholder="Nome completo"
                    name="nome"
                    value={acompanhante.nome}
                    onChange={this.handleAcompanhante}
                    onBlur={this.verificaSeTudoEstaPreenchido}
                    className="dados-pessoais__input"
                  />
                </div>
              </div>
              <div className="dados-pessoais__input-row">
                <div className="dados-pessoais__input-wrapper">
                  <input
                    type="text"
                    placeholder="RG"
                    name="rg"
                    value={acompanhante.rg}
                    onChange={this.handleAcompanhante}
                    onBlur={this.verificaSeTudoEstaPreenchido}
                    className="dados-pessoais__input"
                  />
                </div>
              </div>
            </div>
          </>
        )}

        <span className="dados-pessoais__titulo titulo-4">Dados da Franquia</span>
        <div className="dados-pessoais__inputs">
          <div className="dados-pessoais__input-row dados-pessoais__input-row--multiple">
            <div className="dados-pessoais__input-wrapper">
              <MaskedInput
                mask="11.111.111/1111-11"
                type="text"
                placeholder="CNPJ"
                name="cnpj"
                value={franquia.cnpj}
                onChange={this.handleFranquiaInput}
                onBlur={this.verificaSeTudoEstaPreenchido}
                className="dados-pessoais__input"
              />
            </div>
            <div className="dados-pessoais__input-wrapper">
              <input
                type="text"
                placeholder="Razão Social"
                name="razao_social"
                value={franquia.razao_social}
                onChange={this.handleFranquiaInput}
                onBlur={this.verificaSeTudoEstaPreenchido}
                className="dados-pessoais__input"
              />
            </div>
          </div>
          <div className="dados-pessoais__input-row dados-pessoais__input-row--multiple">
            <div className="dados-pessoais__input-wrapper">
              <input
                type="text"
                placeholder="Qtd. de Clinícas"
                name="quantidade_pdvs"
                value={franquia.quantidade_pdvs}
                onChange={this.handleFranquiaInput}
                onBlur={this.verificaSeTudoEstaPreenchido}
                className="dados-pessoais__input"
              />
            </div>
            {/* <div className="dados-pessoais__input-wrapper">
              <input
                type="text"
                placeholder="Centro de Custo (extranet)"
                name="centro_custos"
                value={franquia.centro_custos}
                onChange={this.handleFranquiaInput}
                onBlur={this.verificaSeTudoEstaPreenchido}
                className="dados-pessoais__input"
              />
            </div> */}
            <div className="dados-pessoais__input-wrapper">
              <MaskedInput
                mask="(11) 11111-1111"
                type="text"
                placeholder="Tel Comercial"
                name="telefone"
                value={franquia.telefone}
                onChange={this.handleFranquiaInput}
                onBlur={this.verificaSeTudoEstaPreenchido}
                className="dados-pessoais__input"
              />
            </div>
          </div>
          <div className="dados-pessoais__input-row">
            <div className="dados-pessoais__input-wrapper">
              <textarea
                type="text"
                placeholder="Unidades Ex: OdontoCompany São Paulo - Centro / OdontoCompany São Paulo - Tietê"
                name="centro_custos"
                value={franquia.centro_custos}
                onChange={this.handleFranquiaInput}
                onBlur={this.verificaSeTudoEstaPreenchido}
                className="dados-pessoais__input dados-pessoais__input--textarea"
              />
            </div>
          </div>
          <div className="dados-pessoais__input-row">
            <div className="dados-pessoais__input-wrapper">
              <input
                type="text"
                placeholder="Endereço Comercial"
                name="endereco"
                value={franquia.endereco}
                onChange={this.handleFranquiaInput}
                onBlur={this.verificaSeTudoEstaPreenchido}
                className="dados-pessoais__input"
              />
            </div>
          </div>
          <div className="dados-pessoais__input-row dados-pessoais__input-row--multiple">
            <div className="dados-pessoais__input-wrapper">
              <input
                type="text"
                placeholder="Cidade"
                name="cidade"
                value={franquia.cidade}
                onChange={this.handleFranquiaInput}
                onBlur={this.verificaSeTudoEstaPreenchido}
                className="dados-pessoais__input"
              />
            </div>
            <div className="dados-pessoais__input-wrapper">
              <select
                name="estado"
                value={franquia.estado}
                onChange={this.handleFranquiaInput}
                className="dados-pessoais__input dados-pessoais__input--select"
              >
                <option value="">UF</option>
                {estados.length
                  && estados.map(estado => (
                    <option key={estado} value={estado}>
                      {estado}
                    </option>
                  ))}
              </select>
            </div>
            <div className="dados-pessoais__input-wrapper">
              <MaskedInput
                type="text"
                placeholder="CEP"
                mask="11111-111"
                size="9"
                name="cep"
                value={franquia.cep}
                onChange={this.handleFranquiaInput}
                onBlur={this.verificaSeTudoEstaPreenchido}
                className="dados-pessoais__input"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DadosPessoais.propTypes = {
  dadosPessoaisPreenchidos: PropTypes.bool,
  atualizaLead: PropTypes.func.isRequired,
  lead: PropTypes.object,
};

DadosPessoais.defaultProps = {
  dadosPessoaisPreenchidos: false,
  lead: {},
};

export default DadosPessoais;
