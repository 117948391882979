//mock data
//import { evento } from "./mock";
//import client from "../../client";

export const ADICIONA_AO_CARRINHO = "ADICIONA_AO_CARRINHO";
export const REMOVE_DO_CARRINHO = "REMOVE_DO_CARRINHO";
export const LIMPA_CARRINHO = "LIMPA_CARRINHO";
export const INGRESSOS_ESCOLHIDOS = "INGRESSOS_ESCOLHIDOS";
export const ATUALIZA_HOSPEDAGEM_NO_CARRINHO = "ATUALIZA_HOSPEDAGEM_NO_CARRINHO";
export const REMOVE_HOSPEDAGEM_NO_CARRINHO = "REMOVE_HOSPEDAGEM_NO_CARRINHO";
export const ATUALIZA_LEAD = "ATUALIZA_LEAD";
export const ATUALIZA_MEIO_PAGAMENTO = "ATUALIZA_MEIO_PAGAMENTO";
export const LIMPA_COMPRAR = "LIMPA_COMPRAR";
export const ATUALIZA_EVENTO = "ATUALIZA_EVENTO";

export function atualizaCarrinhoAction(produtoId, quantidade, observacao) {
  return (dispatch, getState) => {
    const {
      comprar: { carrinho },
    } = getState();

    return dispatch({
      type: ADICIONA_AO_CARRINHO,
      data: {
        carrinho: {
          id: produtoId,
          quantidade,
          observacao,
        },
        qtd: 1,
      },
    });
  };
}

export function removeCarrinhoAction(produtoId) {
  return (dispatch, getState) => {
    const {
      comprar: { carrinho },
    } = getState();

    return dispatch({
      type: REMOVE_DO_CARRINHO,
      data: carrinho.filter(item => item.id !== produtoId),
    });
  };
}

export function ingressosEscolhidosAction() {
  return {
    type: INGRESSOS_ESCOLHIDOS,
  };
}

export function limpaCarrinhoAction() {
  return {
    type: LIMPA_CARRINHO,
  };
}

export function limpaComprarAction() {
  return {
    type: LIMPA_COMPRAR,
  };
}

export function atualizaHospedagemNoCarrinhoAction(dias) {
  return (dispatch, getState) => {
    const {
      comprar: { carrinho },
      evento: {
        evento: { produtos },
      },
    } = getState();

    //vamos pegar os ids de produtos do tipo hospedagem
    const hospedagens = produtos.filter(produto => produto.produto_tipo_id === 2).map(produto => produto.id);

    //vamos limpar o carrinho de outras hospedagens
    const carrinhoLimpo = carrinho.filter(item => !hospedagens.includes(item.id));

    //agora vamos adicionar os novos dias
    const carrinhoNovo = [...carrinhoLimpo, ...dias];

    return dispatch({
      type: ATUALIZA_HOSPEDAGEM_NO_CARRINHO,
      data: carrinhoNovo,
    });
  };
}

export function removeHospedagemNoCarrinhoAction() {
  return (dispatch, getState) => {
    const {
      comprar: { carrinho },
      evento: {
        evento: { produtos },
      },
    } = getState();

    //vamos pegar os ids de produtos do tipo hospedagem
    const hospedagens = produtos.filter(produto => produto.produto_tipo_id === 2).map(produto => produto.id);

    //vamos limpar o carrinho de outras hospedagens
    const carrinhoLimpo = carrinho.filter(item => !hospedagens.includes(item.id));

    return dispatch({
      type: ATUALIZA_HOSPEDAGEM_NO_CARRINHO,
      data: carrinhoLimpo,
    });
  };
}

export function atualizaLeadAction(preenchido, lead) {
  return dispatch => dispatch({
    type: ATUALIZA_LEAD,
    data: {
      preenchido,
      lead,
    },
  });
}

export function atualizaEventoAction(evento) {
  return dispatch => dispatch({
    type: ATUALIZA_EVENTO,
    data: {
      evento,
    },
  });
}

export function atualizaMeioPagamentoAction(meioPagamento) {
  return dispatch => dispatch({
    type: ATUALIZA_MEIO_PAGAMENTO,
    data: meioPagamento,
  });
}
