import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

//em sua fase inicial na tela inicial ele eh basicamente estatico
//na tela de checkout ele realmente assume a funcao de mostrar onde esta
const Progresso = ({
  subtotal,
  taxa,
  total,
  comprarCompleto,
  ingressosEscolhidos,
  hospedagemEscolhida,
  dadosPessoaisPreenchidos,
  dadosPagamentoPreenchidos,
  limpaCarrinho,
}) => (
  <div className="progresso">
    {/* <div
      className={classNames("progresso__item", "progresso__item--ativo", {
        "progresso__item--completo": comprarCompleto && ingressosEscolhidos,
      })}
    >
      <span className="progresso__item-nome">Inscrição</span>
      <span className={`progresso__item-circulo ${window.location.pathname !== "/odonto-2020" ? "" : "progresso__item-circulo--borda-home"}`}>
        <svg className="progresso__item-check">
          <use xlinkHref="/img/icones.svg#icon-checkmark" />
        </svg>
      </span>
    </div> */}
    <div
      className={classNames("progresso__item", "progresso__item--ativo", {
        "progresso__item--completo": comprarCompleto && hospedagemEscolhida,
      })}
    >
      <span className="progresso__item-nome">Hospedagem</span>
      <span className={`progresso__item-circulo ${window.location.pathname !== "/odonto-2020" ? "" : "progresso__item-circulo--borda-home"}`}>
        <svg className="progresso__item-check">
          <use xlinkHref="/img/icones.svg#icon-checkmark" />
        </svg>
      </span>
    </div>
    <div
      className={classNames("progresso__item", {
        "progresso__item--completo": comprarCompleto && dadosPessoaisPreenchidos,
      })}
    >
      <span className="progresso__item-nome">Seus Dados</span>
      <span className={`progresso__item-circulo ${window.location.pathname !== "/odonto-2020" ? "" : "progresso__item-circulo--borda-home"}`}>
        <svg className="progresso__item-check">
          <use xlinkHref="/img/icones.svg#icon-checkmark" />
        </svg>
      </span>
    </div>
    <div
      className={classNames("progresso__item", {
        "progresso__item--completo": comprarCompleto && dadosPagamentoPreenchidos,
      })}
    >
      <span className="progresso__item-nome">Pagamento</span>
      <span className={`progresso__item-circulo ${window.location.pathname !== "/odonto-2020" ? "" : "progresso__item-circulo--borda-home"}`}>
        <svg className="progresso__item-check">
          <use xlinkHref="/img/icones.svg#icon-checkmark" />
        </svg>
      </span>
    </div>

    {comprarCompleto && (
      <div className="progresso__total">
        <div className="progresso__total-item">
          <span className="progresso__total-label">Subtotal</span>
          <span className="progresso__total-valor">
            {`R$ ${parseFloat(subtotal.toFixed(2)).toLocaleString("pt-BR")}`}
          </span>
        </div>
        {/* <div className="progresso__total-item">
          <span className="progresso__total-label">Taxa do cartão</span>
          <span className="progresso__total-valor">{`R$ ${parseFloat(taxa.toFixed(2)).toLocaleString("pt-BR")}`}</span>
        </div> */}
        <div className="progresso__total-item progresso__total-item--total">
          <span className="progresso__total-label">Total</span>
          <span className="progresso__total-valor">{`R$ ${parseFloat(total.toFixed(2)).toLocaleString("pt-BR")}`}</span>
        </div>

        <span className="progresso__limpar" onClick={limpaCarrinho} role="button" tabIndex={0}>
          Limpar escolhas
        </span>
      </div>
    )}
  </div>
);

Progresso.propTypes = {
  total: PropTypes.number,
  taxa: PropTypes.number,
  subtotal: PropTypes.number,
  comprarCompleto: PropTypes.bool,
  ingressosEscolhidos: PropTypes.bool,
  hospedagemEscolhida: PropTypes.bool,
  dadosPessoaisPreenchidos: PropTypes.bool,
  dadosPagamentoPreenchidos: PropTypes.bool,
  limpaCarrinho: PropTypes.func.isRequired,
};

Progresso.defaultProps = {
  total: 0,
  subtotal: 0,
  taxa: 0,
  comprarCompleto: false,
  ingressosEscolhidos: false,
  hospedagemEscolhida: false,
  dadosPessoaisPreenchidos: false,
  dadosPagamentoPreenchidos: false,
};

export default Progresso;
