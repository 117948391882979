import React from 'react';
import { Link } from 'react-scroll';

export default function Menu() {
  return (
    <div className="menu">
      <ul className="menu__list">
        <li className="menu__list-item">
          <Link to="descricao" smooth duration={400}>
            O Evento
          </Link>
        </li>
        <li className="menu__list-item">
          <Link to="local" smooth duration={400}>
            Local
          </Link>
        </li>
        <li className="menu__list-item">
          <Link to="palestrantes" smooth duration={400}>
            Convidados
          </Link>
        </li>
        <li className="menu__list-item">
          <Link to="programacao" smooth duration={400}>
            Programação
          </Link>
        </li>
        <li className="menu__list-button">
          <Link to="comprar" smooth duration={400}>
            <button className="botao-4 botao-4--rounded botao-4--uppercase menu__list-button">Garanta sua hospedagem</button>
          </Link>
        </li>
      </ul>
    </div>
  );
}
