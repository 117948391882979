import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import moment from "moment";

class Ingressos extends React.Component {
  //clicar varias vezes faz ativar o debug
  ativarDebug = 0;

  constructor(props) {
    super(props);

    this.state = {
      collapsed: !props.expandido,
      mostraDebug: false,
    };
  }

  toggleCollapsed = () => {
    const { collapsed } = this.state;

    this.setState({
      collapsed: !collapsed,
    });
  };

  mostraDebugTrigger = () => {
    this.ativarDebug += 1;
    if (this.ativarDebug > 4) {
      this.setState({
        mostraDebug: true,
      });
    }
  };

  render() {
    const {
      ingressos,
      adicionarIngressoAoCarrinho,
      removerIngressoAoCarrinho,
      produtosNoCarrinho,
      escolherIngressosEIrParaCheckout,
      subTotal,
      comprarCompleto,
      carrinho,
      ingressosNoCarrinho,
      ingressosQtd,
    } = this.props;

    const { collapsed, mostraDebug } = this.state;

    // console.log(ingressos);

    const ehAntesDoDia28 = moment().isBefore("2019-03-01");

    return (
      <div
        className={classNames("ingressos", {
          "ingressos--completo": comprarCompleto,
          "ingressos--collapsed": comprarCompleto && collapsed,
        })}
      >
        {/* <p className="comprar__encerrado">
          Vendas encerradas na plataforma. Em caso de dúvidas, ligue{" "}
          <a href="tel:5517982216700" className="comprar__encerrado-tel">
            (17) 98221-6700.
          </a>
        </p> */}
        {/* {comprarCompleto && (
          <div className="ingressos__toggle" role="button" tabIndex={0} onClick={this.toggleCollapsed}>
            <h2 className="ingressos__titulo titulo-4">Inscrição</h2>
            <div
              className={classNames("ingressos__expandir", {
                "ingressos__expandir--expandend": !collapsed,
              })}
            >
              <span className="ingressos__expandir-texto">
                {collapsed ? "expandir inscrição" : "ocultar inscrição"}
              </span>
              <svg className="ingressos__expandir-icone">
                <use xlinkHref="/img/icones.svg#icon-chevron-right" />
              </svg>
            </div>
          </div>
        )}

        {ingressos
          && ingressos.length
          && ingressos
            .filter(({ descricao }) => (mostraDebug ? true : !descricao.includes("TESTE")))
            .map(
              ({
                id,
                descricao,
                valor,
                observacao_pagamento: observacaoPagamento,
                disponivel_de: disponivelDe,
                disponivel_ate: disponivelAte,
              }) => {
                const estaNoCarrinho = produtosNoCarrinho.includes(id);

                //se tiver os campos de disponibilidade, verifica se esta disponivel
                const estaDisponivel = (!disponivelDe && !disponivelAte) || moment().isBetween(disponivelDe, disponivelAte, "minute", "[]");
                const passou = disponivelAte && moment().isAfter(disponivelAte, "minute");

                return (
                  <div
                    key={id}
                    className={classNames("ingresso", {
                      "ingresso--indisponivel": !estaDisponivel,
                    })}
                  >
                    <div className="ingresso__titulo">
                      <span className="ingresso__nome">{descricao}</span>
                      <span className="ingresso__nome">Inscrição</span>
                      <span className="ingresso__data">
                        {!passou ? `(De ${moment("2019-11-20 00:00:00").format("DD/MM")} a ${moment("2019-12-06 00:00:00").format("DD/MM")})` : "Esgotado"}
                      </span>
                    </div>
                    <span className="ingresso__valor">
                      {`R$ ${valor},00 por pessoa`}
                      Apenas para franqueados
                    </span>
                    {/* <span className="ingresso__observacao">
                      {observacaoPagamento}
                    </span>
                    {!!estaDisponivel && (
                      <div
                        className={classNames("ingresso__adicionar", {
                          "ingresso__adicionar--adicionado": estaNoCarrinho,
                        })}
                        role="button"
                        tabIndex={0}
                      >
                        <>
                          <svg className="ingresso__adicionar-icone" onClick={() => removerIngressoAoCarrinho(id)}>
                            <use xlinkHref="/img/icones.svg#icon-minus" />
                          </svg>
                          <p className="ingresso__adicionar-texto">
                            {ingressosQtd}
                          </p>
                          {ingressosQtd === 2 ? '' : (
                            <svg className="ingresso__adicionar-icone" onClick={() => adicionarIngressoAoCarrinho(id)}>
                              <use xlinkHref="/img/icones.svg#icon-plus" />
                            </svg>
                          )}
                        </>
                      </div>
                    )}
                    {!estaDisponivel && (
                      <button type="button" className="botao-2 botao-2--sem-sombra" disabled>Indisponível</button>
                    )}
                  </div>
                );
              },
            )} */}

        {/* <div className="ingresso ingresso--indisponivel">
          <div className="ingresso__titulo">
            <span className="ingresso__nome">3° LOTE</span>
          </div>
          <span className="ingresso__valor">-</span>
          <span className="ingresso__observacao">{" "}</span>
          <button type="button" className="botao-2 botao-2--sem-sombra" disabled>Indisponível</button>
        </div> */}

        {/* {subTotal > 0 && (
          <div className="comprar__subtotal" onClick={this.mostraDebugTrigger}>
            <span className="comprar__subtotal-texto">Subtotal:</span>
            <span className="comprar__subtotal-valor">{`R$ ${subTotal},00`}</span>
          </div>
        )} */}

        {!comprarCompleto && (
          <button
            type="button"
            onClick={escolherIngressosEIrParaCheckout}
            className="ingressos__continuar botao-2 botao-2--checkout botao-2--rounded botao-2--uppercase"
            // disabled={!(carrinho && carrinho.length > 0)}
          >
            Reservar Quartos
          </button>
        )}
      </div>
    );
  }
}

Ingressos.propTypes = {
  ingressos: PropTypes.array.isRequired,
  produtosNoCarrinho: PropTypes.array.isRequired,
  adicionarIngressoAoCarrinho: PropTypes.func.isRequired,
  escolherIngressosEIrParaCheckout: PropTypes.func.isRequired,
  subTotal: PropTypes.number.isRequired,
  comprarCompleto: PropTypes.bool,
  expandido: PropTypes.bool,
};

Ingressos.defaultProps = {
  comprarCompleto: false,
  expandido: false,
};

export default Ingressos;
