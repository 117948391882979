import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";

const traducao = {
  calendarLabel: "Calendário",
  clearDate: "Limpar data",
  clearDates: "Limpar datas",
  closeDatePicker: "Fechar",
  enterKey: "Tecla enter",
  escape: "Tecla esc",
  focusStartDate: "Escolha a data de sua viagem",
  hideKeyboardShortcutsPanel: "Fechar painel de atalhos",
  homeEnd: "Teclas home e end",
  jumpToNextMonth: "Vá para a frente para ir para o próximo mês",
  jumpToPrevMonth: "Vá para trás para ir para o mês anterior",
  keyboardNavigationInstructions: "Navegue usando o teclado",
  keyboardShortcuts: "Atalhos de teclado",
  leftArrowRightArrow: "Teclas de seta para direita e esquerda",
  moveFocusByOneDay: "Ir para trás (esquerda) ou frente (direita) em um dia.",
  moveFocusByOneMonth: "Trocar meses.",
  moveFocusByOneWeek: "Ir para trás (cima) ou frente (baixo) em uma semana.",
  moveFocustoStartAndEndOfWeek: "Ir para o primeiro ou último dia da semana",
  openThisPanel: "Abrir este painel.",
  pageUpPageDown: "Teclas page up e page down",
  questionMark: "Interrogação",
  returnFocusToInput: "Retornar ao campo de seleção",
  selectFocusedDate: "Selecionar a data em foco",
  showKeyboardShortcutsPanel: "Abrir o painel de atalhos.",
  upArrowDownArrow: "Teclas para cima e para baixo",
};

class DatePicker extends React.Component {
  state = {
    focusedInput: null,
  };

  onFocusChange = (focusedInput) => {
    this.setState({ focusedInput });
  };

  foraDoRangeDoEvento = (date) => {
    const { de, ate } = this.props;
    const dateFormatada = date.format("YYYY-MM-DD");

    return dateFormatada < de || dateFormatada > ate;
  };

  render() {
    const {
      modificadorId, de, startDate, endDate, onDatesChange,
    } = this.props;
    const { focusedInput } = this.state;

    return (
      <div className="datepicker-wrapper">
        <DateRangePicker
          startDateId={`${modificadorId}-start-date`}
          endDateId={`${modificadorId}-end-date`}
          startDate={startDate}
          endDate={endDate}
          onDatesChange={onDatesChange}
          onFocusChange={this.onFocusChange}
          focusedInput={focusedInput}
          isOutsideRange={this.foraDoRangeDoEvento}
          startDatePlaceholderText="De"
          endDatePlaceholderText="Até"
          phrases={traducao}
          numberOfMonths={1}
          initialVisibleMonth={() => moment(de)}
        />
      </div>
    );
  }
}

DatePicker.propTypes = {
  modificadorId: PropTypes.string.isRequired,
  de: PropTypes.string.isRequired,
  ate: PropTypes.string.isRequired,
  onDatesChange: PropTypes.func.isRequired,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
};

DatePicker.defaultProps = {
  startDate: null,
  endDate: null,
};

export default DatePicker;
