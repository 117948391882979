import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { buscaOpcoes, enviaPergunta } from "./perguntar.helpers";

function CriarPergunta({ evento, eventoPath }) {
  const [perguntaFeita, setPerguntaFeita] = useState(false);
  const [estaEnviandoPergunta, setEstaEnviandoPergunta] = useState(false);
  const [temErroPreenchimento, setTemErroPreenchimento] = useState(false);

  //opcoes primarias
  const [carregandoOpcoes, setCarregandoOpcoes] = useState(true);
  const [opcoesPrimarias, setOpcoesPrimarias] = useState([]);
  const [opcoesDestino, setOpcoesDestino] = useState([]);

  //os dados da pergunta
  const [pergunta, setPergunta] = useState({
    user_nome: "",
    user_franquia: "",
    user_info_principal: "",
    user_info_secundaria: "",
    destino: "",
    pergunta: "",
  });

  useEffect(() => {
    //buscando opcoes das perguntas
    buscaOpcoes().then((res) => {
      if (res) {
        setOpcoesPrimarias(res.primarias || []);
        setOpcoesDestino(res.destino || []);
      }
      setCarregandoOpcoes(false);
    });
  }, [eventoPath]);

  const onInputChange = ({ currentTarget: { name, value } }) => {
    setPergunta({
      ...pergunta,
      [name]: value,
    });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    setEstaEnviandoPergunta(true);

    if (pergunta.user_nome && pergunta.user_franquia && pergunta.destino && pergunta.pergunta) {
      setTemErroPreenchimento(false);

      enviaPergunta(eventoPath, pergunta).then((perguntaEnviada) => {
        console.log(perguntaEnviada);
        setPerguntaFeita(true);
      });

      setEstaEnviandoPergunta(false);
    } else {
      setTemErroPreenchimento(true);
      setEstaEnviandoPergunta(false);
    }

    console.log(pergunta);
  };

  const onPerguntarNovamente = () => {
    setTemErroPreenchimento(false);
    setEstaEnviandoPergunta(false);
    setPerguntaFeita(false);
    setPergunta({
      user_nome: "",
      user_franquia: "",
      user_info_principal: "",
      user_info_secundaria: "",
      destino: "",
      pergunta: "",
    });
  };

  return (
    <div className="criar-pergunta">
      {!perguntaFeita ? (
        <form onSubmit={onFormSubmit} className="pergunta-form">
          <h2 className="titulo-4 titulo-4--pequeno">Pergunte ao Bob's</h2>
          <div className="pergunta-form__input-wrapper">
            <input
              type="text"
              placeholder="Nome completo"
              name="user_nome"
              value={pergunta.user_nome}
              onChange={onInputChange}
              className="pergunta-form__input"
            />
          </div>
          <div className="pergunta-form__input-wrapper">
            <input
              type="text"
              placeholder="Franquia"
              name="user_franquia"
              value={pergunta.user_franquia}
              onChange={onInputChange}
              className="pergunta-form__input"
            />
          </div>
          {!carregandoOpcoes && (
            <div className="pergunta-form__input-wrapper">
              {opcoesDestino.length ? (
                <select
                  type="text"
                  name="destino"
                  value={pergunta.destino}
                  onChange={onInputChange}
                  className="pergunta-form__input pergunta-form__input--select"
                >
                  <option value="" disabled>
                    Destino da pergunta
                  </option>
                  {!!opcoesDestino
                    && opcoesDestino.map(opcao => (
                      <option value={opcao} key={opcao}>
                        {opcao}
                      </option>
                    ))}
                </select>
              ) : (
                <input
                  type="text"
                  placeholder="Destino da pergunta"
                  name="destino"
                  value={pergunta.destino}
                  onChange={onInputChange}
                  className="pergunta-form__input"
                />
              )}
            </div>
          )}
          <div className="pergunta-form__input-wrapper">
            <textarea
              placeholder="Qual a pergunta?"
              name="pergunta"
              value={pergunta.pergunta}
              onChange={onInputChange}
              className="pergunta-form__input"
            />
          </div>
          {temErroPreenchimento && <span className="pergunta-form__erro">Por favor, preencha todos os campos</span>}
          {estaEnviandoPergunta && (
            <div className="pergunta-form__carregando">
              <div className="spinner">
                <div className="rect1" />
                <div className="rect2" />
                <div className="rect3" />
                <div className="rect4" />
                <div className="rect5" />
              </div>
            </div>
          )}
          <button type="submit" className="botao-2 pergunta-form__botao">
            Enviar pergunta
          </button>
        </form>
      ) : (
        <div className="criar-pergunta__pergunta-feita">
          <h3 className="titulo-1">Pergunta enviada!</h3>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum, vel.</p>
          <button type="button" onClick={onPerguntarNovamente} className="botao-2 pergunta-form__botao">
            Nova pergunta
          </button>
        </div>
      )}
    </div>
  );
}

CriarPergunta.propTypes = {
  evento: PropTypes.object.isRequired,
  eventoPath: PropTypes.string.isRequired,
};

export default CriarPergunta;
