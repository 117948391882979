import React from "react";
import PropTypes from "prop-types";

import Menu from './Menu';

const Apresentacao = ({ evento }) => (
  <div className="apresentacao">
    <div className="apresentacao__container container">
      <div className="apresentacao__menu-wrapper">
        {/* <img className="apresentacao__logo-evento" src={evento.logo} alt={evento.titulo} /> */}
        <Menu />
      </div>
      {/* <div className="apresentacao__infos-wrapper">
        {/* <h1 className="apresentacao__titulo titulo-3">{evento.tituloAlternativo}</h1>
        <h2 className="apresentacao__subtitulo">{evento.subTitulo}</h2>
        <button
          className="botao-2 botao-2--rounded botao-2--uppercase apresentacao__participar"
          type="button"
          onClick={() => {
            const top = document.querySelector(".comprar").offsetTop;
            window.scroll({
              top,
              left: 0,
              behavior: "smooth",
            });
          }}
        >
          Quero participar
        </button>
      </div> */}
    </div>
    {!!evento.topo && (
      <picture>
        {/* <source src={evento.topo.bannerMobileSmall} media="(max-width: 400px)" /> */}
        <source
          srcSet={evento.topo.bannerMobile400 || evento.topo.bannerMobile600 || evento.topo.banner}
          media="(max-width: 400px)"
        />
        <source srcSet={evento.topo.bannerMobile600 || evento.topo.banner} media="(max-width: 600px)" />
        <img className="apresentacao__banner" src={evento.topo.banner} alt={evento.titulo} />
      </picture>
    )}
  </div>
);

Apresentacao.propTypes = {
  evento: PropTypes.object.isRequired,
};

export default Apresentacao;
