import React, { Component } from "react";

export class PoliticaPrivacidadeApp extends Component {
  state = {};

  render() {
    return (
      <div className="politica-privacidade-app">
        <h1>Política de Privacidade</h1>
        <p>
          A P9 Digital construiu o aplicativo do Convenção Nacional Bobs como um aplicativo suportado por anúncios. Este
          SERVIÇO é fornecido pela P9 Digital sem nenhum custo e é destinado para uso como está. Esta página é usada
          para informar os visitantes sobre nossas políticas com a coleta, uso e divulgação de Informações Pessoais,
          caso alguém decida usar nosso Serviço. Se você optar por usar nosso Serviço, concordará com a coleta e uso de
          informações relacionadas a essa política. As Informações Pessoais que coletamos são usadas para fornecer e
          melhorar o Serviço. Nós não usaremos ou compartilharemos suas informações com ninguém, exceto conforme
          descrito nesta Política de Privacidade. Os termos usados ​​nesta Política de Privacidade têm os mesmos
          significados que os nossos Termos e Condições, que podem ser acessados ​​no Convenção Nacional Bob, a menos
          que definido de outra forma nesta Política de Privacidade.
        </p>
        <p>
          Recolha e Uso de Informação. Para uma melhor experiência, ao usar nosso Serviço, podemos exigir que você nos
          forneça algumas informações pessoalmente identificáveis, incluindo, mas não se limitando a, nome, e-mail. As
          informações que solicitamos serão retidas por nós e usadas conforme descrito nesta política de privacidade. O
          aplicativo usa serviços de terceiros que podem coletar informações usadas para identificá-lo. Link para a
          política de privacidade de provedores de serviços de terceiros usados ​​pelo aplicativo
        </p>
        <p>
          Dados de Log. Queremos informá-lo que sempre que você usar o nosso serviço, em caso de erro no aplicativo,
          coletamos dados e informações (através de produtos de terceiros) em seu telefone chamado Log Data. Esses dados
          de registro podem incluir informações como o endereço IP do dispositivo, o nome do dispositivo, a versão do
          sistema operacional, a configuração do aplicativo ao utilizar nosso serviço, a hora e a data do seu uso do
          serviço e outras estatísticas.
        </p>
        <p>
          Cookies. Cookies são arquivos com uma pequena quantidade de dados que são comumente usados ​​como
          identificadores exclusivos anônimos. Estes são enviados para o seu navegador a partir dos sites que você
          visita e são armazenados na memória interna do seu dispositivo. Este serviço não usa esses cookies
          explicitamente. No entanto, o aplicativo pode usar código de terceiros e bibliotecas que usam cookies para
          coletar informações e melhorar seus serviços. Você tem a opção de aceitar ou recusar esses cookies e saber
          quando um cookie está sendo enviado para o seu dispositivo. Se você optar por recusar nossos cookies, talvez
          não consiga usar algumas partes deste Serviço.
        </p>
        <p>
          Provedores de serviço Podemos empregar empresas e indivíduos de terceiros pelos seguintes motivos: Para
          facilitar o nosso serviço; Para fornecer o serviço em nosso nome; Para executar serviços relacionados a
          serviços; ou Para nos ajudar a analisar como nosso Serviço é usado. Queremos informar aos usuários deste
          Serviço que esses terceiros tenham acesso às suas Informações Pessoais. O motivo é executar as tarefas
          atribuídas a eles em nosso nome. No entanto, eles são obrigados a não divulgar ou usar as informações para
          qualquer outra finalidade.
        </p>
        <p>
          Segurança Valorizamos sua confiança ao nos fornecer suas Informações Pessoais, e por isso estamos nos
          esforçando para usar meios comercialmente aceitáveis ​​de protegê-los. Mas lembre-se de que nenhum método de
          transmissão pela internet ou método de armazenamento eletrônico é 100% seguro e confiável, e não podemos
          garantir sua segurança absoluta. Links para outros sites Este Serviço pode conter links para outros sites. Se
          você clicar em um link de terceiros, você será direcionado para esse site. Observe que esses sites externos
          não são operados por nós. Portanto, recomendamos que você analise a Política de Privacidade desses sites. Não
          temos controle e não assumimos nenhuma responsabilidade pelo conteúdo, políticas de privacidade ou práticas de
          quaisquer sites ou serviços de terceiros.
        </p>
        <p>
          Privacidade infantil Esses Serviços não abordam ninguém com idade inferior a 13 anos. Não coletamos
          intencionalmente informações de identificação pessoal de crianças com menos de 13 anos. No caso de
          descobrirmos que uma criança com menos de 13 anos nos forneceu informações pessoais, imediatamente a excluímos
          de nossos servidores. Se você é pai / mãe ou responsável legal e sabe que seu filho nos forneceu informações
          pessoais, entre em contato conosco para que possamos tomar as providências necessárias. Alterações a esta
          política de privacidade Podemos atualizar nossa Política de Privacidade de tempos em tempos. Assim,
          aconselhamos que você revise esta página periodicamente para quaisquer alterações. Vamos notificá-lo de
          quaisquer alterações, publicando a nova Política de Privacidade nesta página. Estas alterações entram em vigor
          imediatamente após serem publicadas nesta página. Contate-Nos Se você tiver dúvidas ou sugestões sobre nossa
          Política de Privacidade, não hesite em nos contatar.
        </p>
      </div>
    );
  }
}

export default PoliticaPrivacidadeApp;
