import React from "react";
import PropTypes from "prop-types";

import { Element } from 'react-scroll';

import Descricao from "./Descricao";
import Palestrantes from "./Palestrantes";
import AtracaoPrincipal from './AtracaoPrincipal';
import Programacao from "./Programacao";
import Local from "./Local";
import Ingressos from "../comprar/Ingressos";

const Detalhes = ({ evento }) => {
  const {
    descricao, tituloAlternativo, convidados, programacao, hoteis,
  } = evento;

  return (
    <div className="detalhes">
      <div className="detalhes__container">

        <Element className="container" name="descricao">
          <div className="secao-label">Descrição</div>
          <Descricao descricao={descricao} titulo={tituloAlternativo} />
        </Element>

        <div className="atracao-background">
          <Element className="container" name="palestrantes">
            <div className="secao-label">Convidados</div>
            <AtracaoPrincipal />
          </Element>
        </div>

        <div className="palestrantes-background">
          <Element className="container" name="palestrantes">
            <div className="secao-label">Convidados</div>
            <Palestrantes convidados={convidados} />
          </Element>
        </div>

        <Element className="container" name="programacao">
          <div className="secao-label">Programação</div>
        </Element>
        <Programacao programacao={programacao} />

        <Element className="container" name="local">
          <div className="secao-label">Local do Evento</div>
          <Local hoteis={hoteis} />
        </Element>

        <Element className="container" name="inscricao">
          <div className="secao-label">Hospedagem</div>
          <h2 className="titulo-4 titulo-4--grande detalhes__comprar-titulo">Garanta já sua hospedagem</h2>
          {/* <Ingressos /> */}
        </Element>
      </div>
    </div>
  );
};

Detalhes.propTypes = {
  evento: PropTypes.object.isRequired,
};

export default Detalhes;
