import {
  ADICIONA_AO_CARRINHO,
  REMOVE_DO_CARRINHO,
  LIMPA_CARRINHO,
  INGRESSOS_ESCOLHIDOS,
  ATUALIZA_HOSPEDAGEM_NO_CARRINHO,
  REMOVE_HOSPEDAGEM_NO_CARRINHO,
  ATUALIZA_LEAD,
  ATUALIZA_EVENTO,
  ATUALIZA_MEIO_PAGAMENTO,
  LIMPA_COMPRAR,
} from "./actions";

const initialState = {
  ingressosEscolhidos: false,
  hospedagemEscolhida: false,
  dadosPessoaisPreenchidos: false,
  dadosPagamentosPreenchidos: false,
  meioPagamento: "sem-pagamento",
  evento_escolhido: "",
  carrinho: [],
  ingressos: 0,
  lead: {},
};

export default function (state = initialState, action) {
  const { type, data } = action;
  switch (type) {
  case ADICIONA_AO_CARRINHO:
    return {
      ...state,
      ingressosEscolhidos: true,
      carrinho: [...state.carrinho, data.carrinho],
      ingressos: state.ingressos + data.qtd,
    };
  case REMOVE_DO_CARRINHO:
    return {
      ...state,
      carrinho: data,
      ingressos: 0,
    };
  case LIMPA_CARRINHO:
    return {
      ...state,
      ingressosEscolhidos: false,
      hospedagemEscolhida: false,
      carrinho: [],
      ingressos: 0,
    };
  case INGRESSOS_ESCOLHIDOS:
    return {
      ...state,
      ingressosEscolhidos: true,
    };
  case ATUALIZA_HOSPEDAGEM_NO_CARRINHO:
    return {
      ...state,
      hospedagemEscolhida: true,
      carrinho: data,
    };
  case REMOVE_HOSPEDAGEM_NO_CARRINHO:
    return {
      ...state,
      hospedagemEscolhida: true,
      carrinho: data,
    };
  case ATUALIZA_LEAD:
    return {
      ...state,
      dadosPessoaisPreenchidos: data.preenchido,
      lead: data.lead,
    };
  case ATUALIZA_EVENTO:
    return {
      ...state,
      evento_escolhido: data.evento,
    };
  case ATUALIZA_MEIO_PAGAMENTO:
    return {
      ...state,
      meioPagamento: data,
    };

    //reseta o state inicial do reducer
  case LIMPA_COMPRAR:
    return {
      ingressosEscolhidos: false,
      hospedagemEscolhida: false,
      dadosPessoaisPreenchidos: false,
      dadosPagamentosPreenchidos: false,
      meioPagamento: "sem-pagamento",
      carrinho: [],
      lead: {},
      ingressos: 0,
    };

  default:
    return state;
  }
}
