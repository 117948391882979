import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";
import { Element } from 'react-scroll';
import { buscaEventoAction } from "./actions";

import Apresentacao from "./Apresentacao";
import Detalhes from "./Detalhes";
import Comprar from "../comprar/Comprar";
import Footer from "./Footer";

const Evento = ({
  buscaEvento,
  eventoCarregado,
  evento,
  eventoCarregadoEm,
  match: {
    params: { evento: eventoPath },
  },
}) => {
  //se o evento ja estiver em cache, vamos verificar

  useEffect(() => {
    const validade = 5 * 60 * 1000; //5 minutos
    // const validade = 0;
    if (evento.path !== eventoPath) {
      console.log("Nada em storage ou path de evento diferente, vamos buscar! 🔎");
      localStorage.clear();
      buscaEvento(eventoPath);
    } else {
      console.log("Tudo igual");
    }

    if (!eventoCarregado || evento.path !== eventoPath) {
      console.log("Nada em storage ou path de evento diferente, vamos buscar! 🔎");
      buscaEvento(eventoPath);
    }

    if (new Date() - new Date(eventoCarregadoEm) > validade) {
      console.log("Evento armazenado em storage já expirou, buscando novo ⏰");
      buscaEvento(eventoPath);
    }

    // buscaEvento(eventoPath);
  }, []);

  if (eventoPath !== "odonto-2020") {
    return <Redirect to="/odonto-2020" />;
  }

  return (
    <div className="evento">
      <Apresentacao evento={evento} />
      {eventoCarregado && <Detalhes evento={evento} />}
      <Element name="comprar">
        <Comprar />
      </Element>
      <div className="container">
        {/* <p className="evento__texto-participacao">Sua participação é muito importante neste evento comemorativo. Mas, devido a capacidade do local, a quantidade de convidados é limitada. Portanto, não deixe para fazer a sua inscrição na última hora.</p> */}

        <p className="evento__texto-participacao evento__texto-participacao--center">Mais Informações:</p>
        <a href="mailto:odontocompany@p9eventos.com.br" className="evento__texto-participacao evento__texto-participacao--center">odontocompany@p9eventos.com.br</a>
        <p className="evento__texto-participacao evento__texto-participacao--center">(17) 98221.6700</p>

        <div className="dados-pagamento__texto-legal-wrapper">
          <div className="dados-pagamento__texto-legal">
            <strong>TERMOS E CONDIÇÕES GERAIS PARA INSCRIÇÃO:</strong> <br />
            <li>
                Será considerado participante apenas uma representante legal (franqueado ou sócio) por franquia.
            </li>
            <li>
                Unidades inadimplentes com a Franqueadora, em relação a Royalties ou Fundo Nacional de Marketing, ou qualquer outro débito, estarão impedidas de participar até que o seu débito seja regularizado com a Franqueadora.
            </li>
            <li>
                O representante legal inscrito poderá indicar apenas mais um nome, a título de acompanhante, que ficará sujeito a confirmação por parte da organização do evento, considerando o número de inscritos versus a capacidade local do salão onde será realizado o evento.
            </li>
            <li>
                Em relação a hospedagem, para aqueles que optarem e necessitarem da mesma, as condições negociadas com o nosso parceiro Maksoud Plaza, permanecem inalteradas. Ou seja, podem aproveitar as vantagens oferecidas e efetuar normalmente a sua reserva e optar pela forma de pagamento disponível no portal.
            </li>
            <li>
              <strong>As inscrições serão encerradas, impreterivelmente, dia 06 dezembro de 2019.</strong>
            </li>
            <li>
                Disposições gerais, não contempladas neste termo, serão analisadas caso a caso pelos organizadores e promotores do evento
            </li>
            <li>
              Em caso de dúvidas, entrar em contato com
              odontocompany@p9eventos.com.br ou (17) 98221-6700
            </li>
            <li>
                Li e concordo com as informações prestadas.
            </li>
          </div>
        </div>
      </div>
      <Footer evento={evento} />
    </div>
  );
};

Evento.propTypes = {
  match: PropTypes.object.isRequired,
  buscaEvento: PropTypes.func.isRequired,
  evento: PropTypes.object.isRequired,
  eventoCarregado: PropTypes.bool,
  eventoCarregadoEm: PropTypes.string,
};

Evento.defaultProps = {
  eventoCarregado: false,
  eventoCarregadoEm: new Date().toString(),
};

const mapStateToProps = state => ({
  evento: state.evento.evento,
  eventoCarregado: state.evento.eventoCarregado,
  eventoCarregadoEm: state.evento.eventoCarregadoEm,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    buscaEvento: buscaEventoAction,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Evento);
