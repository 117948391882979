export function randomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

//retorna a width da viewport
export function viewportWidth() {
  return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
}

//retorna a width da viewport
export function viewportHeight() {
  return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
}

//por enquanto esta hardcoded para alterar somente cores
export function atualizaVariaveisCss(customOptions = {}) {
  console.log("Setando cores 💄💅");

  const defaultOptions = {
    tipo: "",
    valores: [],
    sufixo: "",
  };

  const { tipo, valores, sufixo } = {
    ...defaultOptions,
    ...customOptions,
  };

  valores.forEach((valor, index) => {
    document.documentElement.style.setProperty(
      `--${tipo}-evento-${index + 1}`,
      `${valor + sufixo}`,
    );
  });
}
