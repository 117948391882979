import React from "react";
import { Link } from "react-scroll";

const Local = ({ hoteis }) => (
  <>
    {hoteis.map((hotel) => {
      const qtdFotos = hotel.fotos.length;
      let classFotos = '';

      switch (qtdFotos) {
      case 3:
        classFotos = 'local__fotos-grid-3';
        break;

      case 4:
        classFotos = 'local__fotos-grid-4';
        break;

      default:
        break;
      }

      return (
        <div className="local" key={hotel.data}>
          <div className="local__infos">
            <h2 className="titulo-4 titulo-4--grande local__titulo">{hotel.tipo === 'normal' ? "HOTEL SEDE DO EVENTO" : "LOCAL DO EVENTO"}</h2>
            <span className="local__data">{hotel.data}</span>

            <p className="local__nome-hotel">{hotel.nome}</p>
            <span className="local__paragrafo">{hotel.endereco}</span>

            <p className="local__paragrafo">{hotel.descricao}</p>

            {hotel.opcoes && (
              <div className="local__lista-splash">
                <ul className="local__lista">
                  {hotel.opcoes && hotel.opcoes.map(opcao => (
                    <li key={opcao}>{opcao}</li>
                  ))}
                </ul>
                <p className="local__splash">Tarifa negociada para os participantes do evento!</p>
              </div>
            )}

            {hotel.tipo === 'normal' ? (
              <Link to="comprar" smooth duration={400}>
                <button className="botao-2 botao-2--rounded botao-2--uppercase local__button" type="button">
                GARANTA JÁ SUA HOSPEDAGEM
                </button>
              </Link>
            ) : ''}

          </div>
          <div className={`local__fotos ${classFotos}`}>
            {hotel.fotos.map((foto, i) => {
              const lastItem = qtdFotos - 1;
              let classItem = `local__foto-${i} local__foto--menor`;

              if (i == lastItem) {
                classItem = `local__foto--maior`;
              }

              return (
                <img
                  src={foto}
                  alt="Courtyard Marriott, Rio de Janeiro/RJ"
                  className={`local__foto ${classItem}`}
                  key={foto}
                />
              );
            })
            }
          </div>
        </div>
      );
    })}
    <div className="local__extras">
      <p className="local__extras-frase">Caso prefira, você poderá reservar diretamente a sua  hospedagem em locais próximos:</p>
      <div className="local__extras-hoteis">
        <div className="local__extras-hotel">
          <h2>Tivoli Mofarrej</h2>
          <p>Alameda Santos, 1437 - Cerqueira César.</p>
          <p>Telefone: (11) 3146-5900</p>
        </div>
        <div className="local__extras-hotel">
          <h2>Ibis Avenida Paulista</h2>
          <p>Av. Paulista, 2355 - Cerqueira César</p>
          <p>Telefone: (11) 3523-3000</p>
        </div>
      </div>
    </div>
  </>
);

export default Local;
