import React, { useState } from 'react';

export default function Programacao({ programacao }) {
  const [drop, setDrop] = useState(false);

  function handleToggle(data) {
    const valor = 0;

    if (drop === data) {
      setDrop(false);
    } else if (drop === 3) {
      setDrop(false);
    } else if (drop && data !== drop) {
      setDrop(3);
    } else {
      setDrop(data);
    }
  }

  return (
    <div className="programacao">
      {programacao.map((dia, i) => (
        <div className="programacao-dia__container" key={dia.data}>
          <div className={`programacao-dia__container programacao-dia__container--${i}`}>
            <div className={`programacao-dia programacao-dia--${i}`}>
              <div>
                <p className="programacao-dia__data">{dia.data}</p>
                <p className="programacao-dia__mes">{dia.mes}</p>
              </div>
              <div className="programacao-dia__infos">
                <p>{dia.local}</p>
                <p>{dia.horario}</p>
                <span onClick={() => handleToggle(i + 1)} className="programacao-dia__link">Ver programação completa</span>
              </div>
            </div>
          </div>
          <div className={`programacao-dia__dropdown programacao-dia__dropdown--${i} ${drop === i + 1 || drop === 3 ? `programacao-dia__dropdown--ativo` : ''}`}>
            <div className={`programacao-dia__dropdown-lista ${drop === i + 1 ? 'programacao-dia__dropdown-lista--ativo' : ''}`}>
              {dia.talks.map((talk, i) => (
                <div key={`${talk.nome}${i}`} className="programacao-dia__dropdown-item">
                  {talk.nome === "Show com Léo Chaves e Banda" || talk.nome === "DJ Diego Fragoso" || talk.nome === "Palavra do Presidente Dr. Paulo Zahr" ? (
                    <div className="programacao-dia__dropdown-item__foto">
                      {talk.foto !== "" && (<img src={talk.foto} alt={talk.nome} />)}
                    </div>
                  ) : (
                    <div className="programacao-dia__dropdown-item__icon">
                      {talk.foto !== "" ? (<img src={talk.foto} alt={talk.nome} />) : <span />}
                    </div>
                  )}
                  <p>{talk.nome}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
