import React from "react";
import PropTypes from 'prop-types';

const Palestrantes = ({ convidados }) => {
  let primeiraLista = [];
  let segundaLista = [];

  if (convidados && convidados.length > 0) {
    primeiraLista = convidados.slice(0, 4);
    segundaLista = convidados.slice(4, 9);
  }

  return (
    <div className="palestrantes">
      {/* <h2 className="titulo-4 titulo-4--grande palestrantes__titulo">Convidados</h2> */}
      <div className="palestrantes__lista">
        {primeiraLista.map(convidado => (
          <div className="palestrantes__item palestrantes__item--menor" key={convidado.nome}>
            <img
              src={convidado.foto}
              alt="Palestrante"
              className="palestrantes__foto palestrantes__foto--cover"
            />
            <p className="palestrantes__nome">
              {convidado.nome}
            </p>
            <div className="palestrantes__sombra palestrantes__sombra--menor" />
          </div>
        ))}
      </div>
      <div className="palestrantes__segunda-lista">
        {segundaLista.map(convidado => (
          <div className="palestrantes__item palestrantes__item--menor" key={convidado.nome}>
            <img
              src={convidado.foto}
              alt="Palestrante"
              className="palestrantes__foto palestrantes__foto--cover"
            />
            <p className="palestrantes__nome">
              {convidado.nome}
            </p>
            <div className="palestrantes__sombra palestrantes__sombra--menor" />
          </div>
        ))}
      </div>
    </div>
  );
};

// Palestrantes.propTypes = {
//   convidados: PropTypes.arrayOf([PropTypes.string.isRequired]).isRequired,
// };

export default Palestrantes;
