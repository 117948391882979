import React from "react";
import PropTypes from "prop-types";

const Obrigado = (props) => {
  const { codigo } = props;

  return (
    <div className="obrigado">
      <h2 className="titulo-4 obrigado__titulo">Obrigado!</h2>
      <p className="paragrafo-1 obrigado__agradecimento">
      Seus dados foram recebidos com sucesso. A sua hospedagem será validada após a confirmação do pagamento.
      </p>
      <p className="obrigado__codigo-pedido">Código do pedido: {codigo}</p>
      <p className="paragrafo-1 obrigado__agradecimento">
      Vamos juntos viver momentos inesquecíveis nesta grande festa! Estamos preparando tudo com muito carinho para você se divertir, confraternizar e se emocionar. <br /> Até lá!
      </p>
      <p className="paragrafo-1 obrigado__agradecimento">
        Para mais informações, entre em contato com a nossa equipe: (17)
        98221-6700 ou pelo email odontocompany@p9eventos.com.br
      </p>
    </div>
  );
};

Obrigado.propTypes = {
  codigo: PropTypes.string.isRequired,
};

export default Obrigado;
