import React, { Component } from "react";
import PropTypes from "prop-types";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import {
  BrowserRouter, Route, Switch, withRouter, Redirect,
} from "react-router-dom";

//redux stuff
import { Provider, connect } from "react-redux";
import store from "./store";

import { atualizaVariaveisCss } from "./helpers";

import Evento from "./components/evento/Evento";
import Checkout from "./components/comprar/Checkout";
import PoliticaPrivacidadeApp from "./components/PoliticaPrivacidadeApp";

import Perguntar from "./components/pergunta/Perguntar";
import ListaPerguntas from "./components/pergunta/ListaPerguntas";
import Contador from "./components/aceites/Contador";

class Wrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      coresCarregadas: false,
    };
  }

  componentWillMount() {
    const { eventoCarregado } = this.props;
    const { coresCarregadas } = this.state;

    if (eventoCarregado && !coresCarregadas) {
      this.atualizaCores();
    }
  }

  //faz o scroll para o topo na troca de pagina
  componentDidUpdate(prevProps) {
    const { eventoCarregado } = this.props;
    const { coresCarregadas } = this.state;

    if (eventoCarregado && !coresCarregadas) {
      this.atualizaCores();
    }

    const { location } = this.props;
    if (location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  //pega as cores do evento e atualiza as variaveis css na raiz da pagina
  //como a raiz fica fora do app react, nao tem problema mexer la
  atualizaCores = () => {
    const {
      evento: { cores },
    } = this.props;

    //pra cada cor, vamos atualizar as variaveis de cor do evento
    //modelo = --color-evento-$
    atualizaVariaveisCss({
      tipo: "color",
      valores: cores,
    });

    this.setState({
      coresCarregadas: true,
    });
  };

  render() {
    const { location } = this.props;

    return (
      <div className="app">
        <div className="content">
          <TransitionGroup>
            <CSSTransition key={location.key} classNames="fade" timeout={300}>
              <Switch location={location}>
                <Route path="/" exact render={() => <Redirect to="odonto-2020" />} />
                <Route path="/:evento" exact component={Evento} />
                <Route path="/:evento/checkout" exact component={Checkout} />
                <Route
                  path="/:evento/checkout/sucesso/:codigo"
                  exact
                  render={props => <Checkout {...props} pedidoFinalizado />}
                />
                <Route path="/:evento/pergunte" exact component={Perguntar} />
                <Route path="/:evento/pergunte/listar-perguntas" exact component={ListaPerguntas} />
                <Route path="/:evento/aceite/contador" exact component={Contador} />
                <Route path="/bobs/politica-privacidade" exact component={PoliticaPrivacidadeApp} />
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    );
  }
}

Wrapper.propTypes = {
  location: PropTypes.object.isRequired,
  evento: PropTypes.object.isRequired,
  eventoCarregado: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  evento: state.evento.evento,
  eventoCarregado: state.evento.eventoCarregado,
});

//adicionando as props da store
const WrapperWithStore = connect(mapStateToProps)(Wrapper);

//adicionando as props do router
const WrapperWithRouter = withRouter(WrapperWithStore);

//componente principal
const App = () => (
  <Provider store={store}>
    <BrowserRouter>
      <WrapperWithRouter />
    </BrowserRouter>
  </Provider>
);

export default App;
