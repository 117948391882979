import React from 'react';


export default function AtracaoPrincipal() {
  return (
    <div className="atracao__conteudo">
      <h2 className="atracao__title">léo chaves e banda</h2>
      <p className="atracao__texto">Nosso evento terá como atração principal o cantor Léo Chaves com a sua super banda. Show ao vivo, Léo vai apresentar grandes sucessos nacionais e  o bom sertanejo que o consagrou musicalmente, fazendo dele um dos principais artistas da música Brasileira. Após o show, muita dança e animação ao som de DJ.</p>
    </div>
  );
}
