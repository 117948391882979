import React from "react";
import PropTypes from "prop-types";
import MaskedInput from "react-maskedinput";
import classNames from "classnames";

class DadosPagamento extends React.Component {
  state = {
    placeHolderDocumentoBoleto: "CPF",
    mascaraDocumentoBoleto: "111.111.111-11",
    bandeirasCartao: ["Visa", "Mastercard", "Amex"],

    boleto: {
      boleto_cpf_cnpj: "",
      boleto_email: "",
      boleto_parcelas: 1,
    },
    cartao: {
      cartao_bandeira: "",
      cartao_numero: "",
      cartao_validade: "",
      cartao_cvv: "",
      cartao_parcelas: 1,
    },
  };

  verificaSeTudoEstaPreenchido = () => {
    const { cartao } = this.state;

    const camposCartao = Object.keys(cartao);

    let cartaoOk = true;
    camposCartao.forEach((campo) => {
      if (!cartao[campo] || cartao[campo] === "") {
        cartaoOk = false;
      }
    });

    if (cartaoOk) {
      console.log("tudo preenchido");
    }
  };

  handleInputBoleto = (event) => {
    const { boleto } = this.state;
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    console.log(name, value);

    boleto[name] = value;

    this.setState({
      boleto,
    });
  };

  handleInputCartao = (event) => {
    const { cartao } = this.state;
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    //se for numero, validar tamanho maximo
    if (name === "cartao_numero" && `${value}`.length > 16) {
      return false;
    }

    cartao[name] = value;

    this.setState({
      cartao,
    });
  };

  trocaMascaraBoleto = ({ placeHolder, mascara }) => {
    this.setState({
      placeHolderDocumentoBoleto: placeHolder,
      mascaraDocumentoBoleto: mascara,
    });
  };

  finalizar = () => {
    const {
      pedirBoleto, pedirCartao, meioPagamento, somenteIngresso,
    } = this.props;

    const { boleto, cartao } = this.state;

    if (meioPagamento === "boleto") {
      pedirBoleto({
        ...boleto,
      });
    } else if (meioPagamento === "cartao") {
      pedirCartao({
        ...cartao,
      });
    } else if (meioPagamento === 'sem-pagamento') {
      somenteIngresso();
    }
  };

  render() {
    const {
      subtotal,
      taxa,
      total,
      erroPagamento,
      meioPagamento,
      totalComAjusteParcelado,
      limpaCarrinho,
      atualizaMeioPagamento,
      parcelasBoleto,
      parcelasCartao,
    } = this.props;

    const {
      boleto,
      mascaraDocumentoBoleto,
      placeHolderDocumentoBoleto,
      bandeirasCartao,
      cartao,
    } = this.state;

    return (

      <>
        {subtotal > 0 && (
          <div className="dados-pagamento">
            <span className="dados-pagamento__titulo titulo-4">
            Dados para pagamento com boleto
            </span>
            <div className="dados-pagamento__meios">
              <button
                className={classNames("botao-2", "dados-pagamento__meio-button", {
                  "dados-pagamento__meio-button--ativo": meioPagamento === "cartao",
                })}
                type="button"
                onClick={() => atualizaMeioPagamento("cartao")}
              >
              Cartão de Crédito
              </button>
              <button
                className={classNames("botao-2", "dados-pagamento__meio-button", {
                  "dados-pagamento__meio-button--ativo": meioPagamento === "boleto",
                })}
                type="button"
                onClick={() => atualizaMeioPagamento("boleto")}
              >
              Boleto
              </button>
            </div>

            {meioPagamento === "boleto" && (
              <div className="dados-pagamento__forms">
                <div className="dados-pagamento__titular">
                  <span className="subtitulo-1 dados-pagamento__form-titulo">
                  Dados do boleto
                  </span>
                  <span className="dados-pagamento__observacao">
                  Informe o CPF ou CNPJ para emissão do boleto e um e-mail ativo.
                  Este boleto será enviado ao e-mail informado.
                  </span>
                  <div className="dados-pagamento__meios">
                    <button
                      className={classNames(
                        "botao-2",
                        "dados-pagamento__meio-button",
                        {
                          "dados-pagamento__meio-button--ativo":
                          placeHolderDocumentoBoleto === "CPF",
                        },
                      )}
                      type="button"
                      onClick={() => this.trocaMascaraBoleto({
                        placeHolder: "CPF",
                        mascara: "111.111.111-11",
                      })
                      }
                    >
                    CPF
                    </button>
                    <button
                      className={classNames(
                        "botao-2",
                        "dados-pagamento__meio-button",
                        {
                          "dados-pagamento__meio-button--ativo":
                          placeHolderDocumentoBoleto === "CNPJ",
                        },
                      )}
                      type="button"
                      onClick={() => this.trocaMascaraBoleto({
                        placeHolder: "CNPJ",
                        mascara: "11.111.111/1111-11",
                      })
                      }
                    >
                    CNPJ
                    </button>
                  </div>
                  <div className="dados-pagamento__input-wrapper">
                    <MaskedInput
                      type="text"
                      placeholder={placeHolderDocumentoBoleto}
                      mask={mascaraDocumentoBoleto}
                      name="boleto_cpf_cnpj"
                      value={boleto.boleto_cpf_cnpj}
                      onChange={this.handleInputBoleto}
                      className="dados-pagamento__input"
                    />
                  </div>
                  <input
                    type="text"
                    placeholder="Email"
                    name="boleto_email"
                    value={boleto.boleto_email}
                    onChange={this.handleInputBoleto}
                    className="dados-pagamento__input"
                  />
                  <select
                    name="boleto_parcelas"
                    value={boleto.boleto_parcelas}
                    onChange={this.handleInputBoleto}
                    className="dados-pagamento__input dados-pagamento__input--select"
                  >
                    <option value={1}>{`R$ ${parseFloat(total.toFixed(2)).toLocaleString("pt-BR")} à vista`}</option>
                    {/* <option value={2}>
                      {`R$ ${parseFloat(parcelasBoleto.toFixed(2)).toLocaleString(
                        "pt-BR",
                      )} em 2 pagamentos`}
                    </option> */}
                  </select>
                </div>
              </div>
            )}

            {meioPagamento === "cartao" && (
              <div className="dados-pagamento__forms">
                <div className="dados-pagamento__titular">
                  <span className="subtitulo-1 dados-pagamento__form-titulo">
                  Dados do cartão
                  </span>
                  {/* <span>
                  *No cartão de crédito acréscimo de 5,3% referente as taxas do
                  cartão.
                </span> */}
                  <select
                    name="cartao_bandeira"
                    value={cartao.cartao_bandeira}
                    onChange={this.handleInputCartao}
                    className="dados-pagamento__input dados-pagamento__input--select"
                  >
                    <option value="" disabled>
                    Escolha uma bandeira
                    </option>
                    {bandeirasCartao
                    && bandeirasCartao.map(bandeira => (
                      <option value={bandeira} key={bandeira}>
                        {bandeira}
                      </option>
                    ))}
                  </select>
                  <input
                    type="text"
                    placeholder="Número"
                    name="cartao_numero"
                    value={cartao.cartao_numero}
                    onChange={this.handleInputCartao}
                    className="dados-pagamento__input"
                  />
                  <div className="dados-pagamento__input-wrapper">
                    <MaskedInput
                      type="text"
                      placeholder="Validade"
                      mask="11/1111"
                      name="cartao_validade"
                      value={cartao.cartao_validade}
                      onChange={this.handleInputCartao}
                      className="dados-pagamento__input"
                    />
                  </div>
                  <div className="dados-pagamento__input-wrapper">
                    <MaskedInput
                      type="text"
                      placeholder="Código de segurança"
                      mask="1111"
                      name="cartao_cvv"
                      value={cartao.cartao_cvv}
                      onChange={this.handleInputCartao}
                      className="dados-pagamento__input"
                    />
                  </div>
                  <select
                    name="cartao_parcelas"
                    value={cartao.cartao_parcelas}
                    onChange={this.handleInputCartao}
                    className="dados-pagamento__input dados-pagamento__input--select"
                  >
                    <option value={1}>{`R$ ${parseFloat(total.toFixed(2)).toLocaleString("pt-BR")} à vista`}</option>
                    <option value={2}>
                      {`R$ ${parseFloat((total / 2).toFixed(2)).toLocaleString("pt-BR")} em 2 pagamentos`}
                    </option>
                    <option value={3}>
                      {`R$ ${parseFloat((total / 3).toFixed(2)).toLocaleString("pt-BR")} em 3 pagamentos`}
                    </option>
                  </select>
                </div>
              </div>
            )}

            {subtotal > 0 && (
              <div className="progresso__total dados-pagamento__total">
                <div className="progresso__total-item">
                  <span className="progresso__total-label">Subtotal</span>
                  <span className="progresso__total-valor">
                    {`R$ ${parseFloat(subtotal.toFixed(2)).toLocaleString(
                      "pt-BR",
                    )}`}
                  </span>
                </div>
                {/* <div className="progresso__total-item">
                <span className="progresso__total-label">Taxa do cartão</span>
                <span className="progresso__total-valor">
                  {`R$ ${parseFloat(taxa.toFixed(2)).toLocaleString("pt-BR")}`}
                </span>
              </div> */}
                <div className="progresso__total-item progresso__total-item--total">
                  <span className="progresso__total-label">Total</span>
                  <span className="progresso__total-valor">
                    {`R$ ${parseFloat(total.toFixed(2)).toLocaleString("pt-BR")}`}
                  </span>
                </div>
                <span
                  className="progresso__limpar"
                  onClick={limpaCarrinho}
                  role="button"
                  tabIndex={0}
                >
                Limpar escolhas
                </span>
              </div>
            )}
            {/* <div className="dados-pagamento__resumo-pedido">Pedido: </div> */}
            {erroPagamento && (
              <div className="erro-pagamento">
                <p className="erro-pagamento__texto">
                Ops! Algo deu errado. Por favor verifique se todos os seus dados
                estão preenchidos corretamente e tente novamente. Caso tenha
                alguma dúvida ou o erro persista, entre em contato com
                odontocompany@p9eventos.com.br ou (17) 98221-6700.
                </p>
              </div>
            )}

            <div className="dados-pagamento__texto-legal-wrapper">
              <div className="dados-pagamento__texto-legal">
                <p>Ao clicar em Finalizar Pedido, afirmo concordar com os Termos e Condições da Inscrição descritos abaixo.</p>
              </div>
            </div>

            <button
              type="button"
              onClick={this.finalizar}
              className="botao-2 dados-pagamento__finalizar"
            >
            Finalizar Pedido
            </button>
            <div className="dados-pagamento__texto-legal-wrapper">
              <div className="dados-pagamento__texto-legal">
                <strong>Importante:</strong> <br />
                <li>
              O preenchimento dos campos acima são obrigatórios, a falta de
              alguma informação poderá comprometer sua inscrição;
                </li>
                <li>
              Em caso de cancelamento da inscrição por parte do franqueado,
              somente será aceito com 30 dias de antecedência da data de inicio
              do evento, podendo este receber o valor da inscrição descontado o
              valor da taxa administrativa;
                </li>
                <li>
              Em caso de cancelamento da inscrição por parte do franqueado, com
              menos de 30 dias de antecedência da data de inicio do evento, será
              cobrado o valor total da inscrição, não tendo direto a devolução
              do valor pago;
                </li>
                <li>
              O não pagamento do boleto bancário implicará em multa seguido de
              protesto e cancelamento automático da inscrição;
                </li>
              </div>
            </div>

            <div className="dados-pagamento__texto-legal-wrapper">
              <div className="dados-pagamento__texto-legal">
                <strong>TERMOS E CONDIÇÕES GERAIS PARA INSCRIÇÃO:</strong> <br />
                <li>
                Será considerado participante apenas uma representante legal (franqueado ou sócio) por franquia.
                </li>
                <li>
                Unidades inadimplentes com a Franqueadora, em relação a Royalties ou Fundo Nacional de Marketing, ou qualquer outro débito, estarão impedidas de participar até que o seu débito seja regularizado com a Franqueadora.
                </li>
                <li>
                O representante legal inscrito poderá indicar apenas mais um nome, a título de acompanhante, que ficará sujeito a confirmação por parte da organização do evento, considerando o número de inscritos versus a capacidade local do salão onde será realizado o evento.
                </li>
                <li>
                Em relação a hospedagem, para aqueles que optarem e necessitarem da mesma, as condições negociadas com o nosso parceiro Maksoud Plaza, permanecem inalteradas. Ou seja, podem aproveitar as vantagens oferecidas e efetuar normalmente a sua reserva e optar pela forma de pagamento disponível no portal.
                </li>
                <li>
                  <strong>As inscrições serão encerradas, impreterivelmente, dia 06 dezembro de 2019.</strong>
                </li>
                <li>
                Disposições gerais, não contempladas neste termo, serão analisadas caso a caso pelos organizadores e promotores do evento
                </li>
                <li>
              Em caso de dúvidas, entrar em contato com
              odontocompany@p9eventos.com.br ou (17) 98221-6700
                </li>
                <li>
                Li e concordo com as informações prestadas.
                </li>
              </div>
            </div>
          </div>
        )}

        {subtotal < 1 && (
          <>
            {erroPagamento && (
              <div className="erro-pagamento">
                <p className="erro-pagamento__texto">
                Ops! Algo deu errado. Por favor verifique se todos os seus dados
                estão preenchidos corretamente e tente novamente. Caso tenha
                alguma dúvida ou o erro persista, entre em contato com
                odontocompany@p9eventos.com.br ou (17) 98221-6700.
                </p>
              </div>
            )}

            {/* <div className="dados-pagamento__texto-legal-wrapper">
              <div className="dados-pagamento__texto-legal">
                <p>Ao clicar em Finalizar Pedido, afirmo concordar com os Termos e Condições da Inscrição descritos abaixo.</p>
              </div>
            </div>

            <button
              type="button"
              onClick={this.finalizar}
              className="botao-2 dados-pagamento__finalizar"
            >
            Finalizar Pedido
            </button> */}

            <div className="dados-pagamento__texto-legal-wrapper">
              <div className="dados-pagamento__texto-legal">
                <strong>Importante:</strong> <br />
                <li>
              O preenchimento dos campos acima são obrigatórios, a falta de
              alguma informação poderá comprometer sua inscrição;
                </li>
                <li>
              Em caso de cancelamento da inscrição por parte do franqueado,
              somente será aceito com 30 dias de antecedência da data de inicio
              do evento, podendo este receber o valor da inscrição descontado o
              valor da taxa administrativa;
                </li>
                <li>
              Em caso de cancelamento da inscrição por parte do franqueado, com
              menos de 30 dias de antecedência da data de inicio do evento, será
              cobrado o valor total da inscrição, não tendo direto a devolução
              do valor pago;
                </li>
                <li>
              O não pagamento do boleto bancário implicará em multa seguido de
              protesto e cancelamento automático da inscrição;
                </li>
              </div>
            </div>

            <div className="dados-pagamento__texto-legal-wrapper">
              <div className="dados-pagamento__texto-legal">
                <strong>TERMOS E CONDIÇÕES GERAIS PARA INSCRIÇÃO:</strong> <br />
                <li>
                Será considerado participante apenas uma representante legal (franqueado ou sócio) por franquia.
                </li>
                <li>
                Unidades inadimplentes com a Franqueadora, em relação a Royalties ou Fundo Nacional de Marketing, ou qualquer outro débito, estarão impedidas de participar até que o seu débito seja regularizado com a Franqueadora.
                </li>
                <li>
                O representante legal inscrito poderá indicar apenas mais um nome, a título de acompanhante, que ficará sujeito a confirmação por parte da organização do evento, considerando o número de inscritos versus a capacidade local do salão onde será realizado o evento.
                </li>
                <li>
                Em relação a hospedagem, para aqueles que optarem e necessitarem da mesma, as condições negociadas com o nosso parceiro Maksoud Plaza, permanecem inalteradas. Ou seja, podem aproveitar as vantagens oferecidas e efetuar normalmente a sua reserva e optar pela forma de pagamento disponível no portal.
                </li>
                <li>
                  <strong>As inscrições serão encerradas, impreterivelmente, dia 06 dezembro de 2019.</strong>
                </li>
                <li>
                Disposições gerais, não contempladas neste termo, serão analisadas caso a caso pelos organizadores e promotores do evento
                </li>
                <li>
              Em caso de dúvidas, entrar em contato com
              odontocompany@p9eventos.com.br ou (17) 98221-6700
                </li>
                <li>
                Li e concordo com as informações prestadas.
                </li>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

DadosPagamento.propTypes = {
  pedirBoleto: PropTypes.func.isRequired,
  pedirCartao: PropTypes.func.isRequired,
  total: PropTypes.number,
  totalComAjusteParcelado: PropTypes.number,
  taxa: PropTypes.number,
  subtotal: PropTypes.number,
  erroPagamento: PropTypes.bool,
  meioPagamento: PropTypes.string,
  atualizaMeioPagamento: PropTypes.func.isRequired,
  limpaCarrinho: PropTypes.func.isRequired,
  parcelasBoleto: PropTypes.number,
  parcelasCartao: PropTypes.number,
};

DadosPagamento.defaultProps = {
  total: 0,
  totalComAjusteParcelado: 0,
  subtotal: 0,
  taxa: 0,
  erroPagamento: false,
  meioPagamento: "boleto",
  parcelasBoleto: 0,
  parcelasCartao: 0,
};

export default DadosPagamento;
