import React from "react";
import * as Sentry from "@sentry/browser";
import ReactDOM from "react-dom";
import moment from "moment";
import ptBRLocale from "moment/locale/pt-br";
import App from "./App";

import "./styles/scss/index.scss";
//import registerServiceWorker from "./registerServiceWorker";

Sentry.init({ dsn: "https://378b74a19785497bae377548533d5253@sentry.io/1798482" });

//config e local do moment
moment.updateLocale("pt-br", ptBRLocale);

ReactDOM.render(<App />, document.getElementById("root"));
//registerServiceWorker();

const storage = localStorage.getItem('redux_localstorage_simple');

const storageFormatado = JSON.parse(storage);

console.log(storageFormatado);

if (storageFormatado && storageFormatado.evento && storageFormatado.evento.evento && storageFormatado.evento.evento.path && storageFormatado.evento.evento.path !== "odonto-2020") {
  console.log("Limpando storage antigo");
  localStorage.removeItem('redux_localstorage_simple');
  localStorage.clear();
} else {
  console.log("Storage OK");
}

//fix que impede o google maps de baixar a porra da Roboto
const head = document.getElementsByTagName("head")[0];
const { insertBefore } = head;
head.insertBefore = (newElement, referenceElement) => {
  if (newElement.href && newElement.href.indexOf("https://fonts.googleapis.com/css?family=Roboto") === 0) {
    return;
  }
  insertBefore.call(head, newElement, referenceElement);
};
