//mock data
//import { evento } from "./mock";
import client from "../../client";

export const BUSCA_EVENTO = "BUSCA_EVENTO";

export function buscaEventoAction(eventoPath) {
  if (!eventoPath || eventoPath === "") {
    return {
      type: "BUSCA_EVENTO_FAILED",
    };
  }

  return async (dispatch) => {
    try {
      const {
        data: { success, data: evento },
      } = await client.get(`/eventos/${eventoPath}`);
      if (success) {
        //reduzindo o nesting dos dados do evento
        const eventoComDados = { ...evento, ...evento.dados };

        return dispatch({
          type: BUSCA_EVENTO,
          data: {
            evento: eventoComDados,
          },
        });
      }
      return {
        type: "BUSCA_EVENTO_FAILED",
      };
    } catch (error) {
      console.error(error);
      return {
        type: "BUSCA_EVENTO_FAILED",
        data: error,
      };
    }
  };
}
