import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import moment from "moment";

import { buscaPerguntas, togglePerguntaRespondida } from "./perguntar.helpers";

function ListaPerguntas({
  match: {
    params: { evento: eventoPath },
  },
}) {
  const [perguntas, setPerguntas] = useState([]);
  const [perguntaAtivaId, setPerguntaAtivaId] = useState(null);
  const [estaCarregando, setEstaCarregando] = useState(true);
  const [temErroCarregamento, setTemErroCarregamento] = useState(false);
  const [ultimoCarregamento, setUltimoCarregamento] = useState(null);
  const [mostrarRespondidas, setMostrarRespondidas] = useState(true);

  const atualizarPerguntas = () => {
    //setEstaCarregando(true);
    setTemErroCarregamento(false);

    buscaPerguntas(eventoPath)
      .then((perguntasBuscadas) => {
        setPerguntas(perguntasBuscadas);
        setUltimoCarregamento(moment());
      })
      .catch((err) => {
        console.log(err);
        setTemErroCarregamento(true);
      })
      .finally(() => {
        if (estaCarregando) {
          setEstaCarregando(false);
        }
      });
  };

  const mostraPergunta = pergunta => setPerguntaAtivaId(pergunta.id);

  const marcaPergunta = (pergunta) => {
    togglePerguntaRespondida(eventoPath, pergunta.id).then(() => atualizarPerguntas());
  };

  useEffect(() => {
    atualizarPerguntas();

    window.perguntasInterval = setInterval(atualizarPerguntas, 60000);

    return () => clearInterval(window.perguntasInterval);
  }, [eventoPath]);

  const perguntasFiltradas = perguntas.filter(({ respondida }) => mostrarRespondidas || !respondida);

  const perguntaAtiva = perguntaAtivaId ? perguntas.find(({ id }) => id === perguntaAtivaId) : null;

  return (
    <div className="lista-perguntas">
      <h2 className="titulo-2">Perguntas</h2>
      <div className="lista-perguntas__carregando">
        {ultimoCarregamento && ultimoCarregamento.isValid() && (
          <div className="lista-perguntas__carregando-info">
            <span>Atualizado: {ultimoCarregamento.fromNow()}*</span>
            <button onClick={atualizarPerguntas} type="button" className="botao-2 botao-2--pequeno">
              Atualizar
            </button>
            <button
              onClick={() => setMostrarRespondidas(!mostrarRespondidas)}
              type="button"
              className="botao-2 botao-2--pequeno"
            >
              {mostrarRespondidas ? "Ocultar lidas" : "Mostrar lidas"}
            </button>
          </div>
        )}
        {estaCarregando && (
          <div className="lista-perguntas__spinner-wrapper">
            <div className="spinner">
              <div className="rect1" />
              <div className="rect2" />
              <div className="rect3" />
              <div className="rect4" />
              <div className="rect5" />
            </div>
          </div>
        )}
      </div>
      {!temErroCarregamento && (
        <table className="perguntas-table table is-bordered is-hoverable is-fullwidth">
          <thead className="perguntas-table__head">
            <tr className="perguntas-table__row perguntas-table__row--head">
              <th className="perguntas-table__title">Nº</th>
              <th className="perguntas-table__title">Nome</th>
              <th className="perguntas-table__title">Loja/Regional</th>
              <th className="perguntas-table__title">Departamento</th>
              <th className="perguntas-table__title">Enviada em</th>
              <th className="perguntas-table__title">Respondida</th>
            </tr>
          </thead>
          <TransitionGroup component="tbody" className="perguntas-table__body">
            {!!perguntasFiltradas
              && !!perguntasFiltradas.length
              && perguntasFiltradas.map(pergunta => (
                <CSSTransition key={pergunta.id} timeout={500} classNames="item">
                  <tr className="perguntas-table__row" onClick={() => mostraPergunta(pergunta)}>
                    <td className="perguntas-table__cell">{pergunta.id}</td>
                    <td className="perguntas-table__cell">{pergunta.user_nome}</td>
                    <td className="perguntas-table__cell">{pergunta.user_franquia}</td>
                    <td className="perguntas-table__cell">{pergunta.destino}</td>
                    <td className="perguntas-table__cell">{moment(pergunta.created_at).format("LL")}</td>
                    <td className="perguntas-table__cell">{pergunta.respondida ? "✅" : "❌"}</td>
                  </tr>
                </CSSTransition>
              ))}
          </TransitionGroup>
        </table>
      )}
      <p>*Atualização automática a cada 1 minuto.</p>
      {!!perguntaAtiva && !temErroCarregamento && (
        <div className="modal is-active">
          <div className="modal-background" />
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Pergunta {perguntaAtiva.id}</p>
              <span role="button" className="efeito-simples" tabIndex={0} onClick={() => setPerguntaAtivaId(null)}>
                Fechar
              </span>
              <button
                type="button"
                className="modal-close is-large delete"
                onClick={() => setPerguntaAtivaId(null)}
                aria-label="close"
              />
            </header>
            <section className="modal-card-body pergunta-conteudo">
              <p className="pergunta-conteudo__linha">
                <strong>Nome: </strong>
                <span>{perguntaAtiva.user_nome}</span>
              </p>
              <p className="pergunta-conteudo__linha">
                <strong>Loja/Regional: </strong>
                <span>{perguntaAtiva.user_franquia}</span>
              </p>
              <p className="pergunta-conteudo__linha">
                <strong>Departamento destino: </strong>
                <span>{perguntaAtiva.destino}</span>
              </p>
              <p className="pergunta-conteudo__linha">
                <strong>Pergunta: </strong>
              </p>
              <p className="pergunta-conteudo__linha">{perguntaAtiva.pergunta}</p>
              <button type="button" onClick={() => marcaPergunta(perguntaAtiva)} className="botao-2 botao-2--pequeno">
                Marcar como {perguntaAtiva.respondida ? "não lida" : "lida"}
              </button>
            </section>
          </div>
        </div>
      )}
      {temErroCarregamento && <p>Ops! Algo deu errado. Por favor, recarregue a página e tente novamente.</p>}
      <div className="footer__creditos">
        <strong>#PartiuEvento</strong> - Plataforma de gestão de eventos e venda de inscrições online
      </div>
    </div>
  );
}

ListaPerguntas.propTypes = {
  match: PropTypes.object.isRequired,
};

export default ListaPerguntas;
