import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

const ComprarHeader = ({ evento }) => (
  <div className="comprar-header">
    <div className="comprar-header__container container">
      <div className="comprar-header__evento">
        <h2 className="comprar-header__titulo">{evento.titulo}</h2>
        <div className="comprar-header__detalhes">
          <div className="comprar-header__detalhe">
            <span className="comprar-header__detalhe-icon-wrapper">
              <svg className="comprar-header__detalhe-icon">
                <use xlinkHref="/img/icones.svg#icon-calendar" />
              </svg>
            </span>
            <span className="comprar-header__detalhe-texto">
              {`${moment(evento.de).format("DD.MM.YYYY")} a ${moment(evento.ate).format("DD.MM.YYYY")}`}
            </span>
          </div>
          <div className="comprar-header__detalhe">
            <span className="comprar-header__detalhe-icon-wrapper">
              <svg className="comprar-header__detalhe-icon">
                <use xlinkHref="/img/icones.svg#icon-location-pin" />
              </svg>
            </span>
            <span className="comprar-header__detalhe-texto">{evento.local}</span>
          </div>
        </div>
      </div>
      <img src={evento["marca-logo"]} alt={evento.marca} className="comprar-header__logo-marca" />
    </div>
  </div>
);

ComprarHeader.propTypes = {
  //eventoCarregado: PropTypes.bool.isRequired,
  evento: PropTypes.object.isRequired,
};

export default ComprarHeader;
