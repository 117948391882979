import React from "react";
import PropTypes from "prop-types";

const Footer = ({ evento }) => (
  <div className="footer">
    <div className="footer__container container">
      <img src={evento["marca-logo"]} alt={evento.marca} className="footer__logo-marca" />
    </div>

    <div className="footer__creditos">
      <strong>#PartiuEvento</strong> - Plataforma de gestão de eventos e venda de inscrições online
    </div>
  </div>
);

Footer.propTypes = {
  evento: PropTypes.object.isRequired,
};

export default Footer;
