import { BUSCA_EVENTO } from "./actions";

const initialState = {
  evento: {},
  eventoCarregado: false,
};

export default function (state = initialState, action) {
  const { type, data } = action;
  switch (type) {
  case BUSCA_EVENTO:
    return {
      ...state,
      evento: data.evento,
      eventoCarregado: true,
      eventoCarregadoEm: new Date().toString(),
    };

  default:
    return state;
  }
}
