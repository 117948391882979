import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import Comprar from "./Comprar";
import Obrigado from "./Obrigado";

const Checkout = ({
  pedidoFinalizado,
  match: {
    params: { evento: eventoPath, codigo },
  },
}) => (
  <div className="checkout">
    <Link to={`/${eventoPath}`} className="checkout__voltar">
      Voltar à pagina do Evento
    </Link>
    {!pedidoFinalizado && <Comprar comprarCompleto />}
    {pedidoFinalizado && <Obrigado codigo={codigo} />}
  </div>
);

Checkout.propTypes = {
  match: PropTypes.object.isRequired,
  pedidoFinalizado: PropTypes.bool,
};

Checkout.defaultProps = {
  pedidoFinalizado: false,
};

export default Checkout;
